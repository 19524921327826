import type { AllHTMLAttributes, FC } from 'react';

export const Facebook: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 101 103" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.3932 34.4387L19.0345 62.3726L42.7225 49.0892L55.3881 62.6465L81.405 34.4387L58.3326 47.3781L45.3932 34.4387ZM50.3906 4.38179C75.9036 4.38179 96.3995 23.5697 96.3995 47.1043C96.3995 70.6389 75.9036 89.8268 50.3906 89.8268C45.6495 89.8268 41.0618 89.1432 36.7654 87.9097L35.946 87.7038L35.1924 88.1157L21.909 95.0301V80.6535L21.0874 80.0356C10.869 72.1725 4.38179 60.3351 4.38179 47.1043C4.38179 23.5697 24.8798 4.38179 50.3906 4.38179ZM50.3906 0C22.6714 0 0 21.0282 0 47.1043C0 61.3626 6.86408 74.0194 17.5272 82.6384V102.218L20.7456 100.507L36.5617 92.2915C40.9763 93.4724 45.5728 94.2086 50.3906 94.2086C78.112 94.2086 100.781 73.1825 100.781 47.1043C100.781 21.0282 78.112 0 50.3906 0Z"
      fill="#fff"
    />
  </svg>
);
