import type { AllHTMLAttributes, FC } from 'react';

export const ChevronUp: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M5.8 17.5L12 11.4L18.2 17.5L19.6 16.1L12 8.60001L4.4 16.1L5.8 17.5Z"
    />
  </svg>
);
