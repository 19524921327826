import type { AllHTMLAttributes, FC } from 'react';

export const AirlineLounge: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <path
      fill="currentColor"
      d="M21.13,15.48c1.91-1.1,2.57-3.56,1.46-5.47-1.1-1.91-3.56-2.57-5.47-1.46-1.91,1.1-2.57,3.56-1.46,5.47,1.11,1.91,3.56,2.57,5.47,1.46"
    />
    <polygon
      fill="currentColor"
      points="46.5 15.56 45.8 18.87 49.48 19.05 46.5 15.56"
    />
    <polygon
      fill="currentColor"
      points="50.04 20.66 42.38 20.41 40.33 30.03 47.98 30.29 50.04 20.66"
    />
    <polygon
      fill="currentColor"
      points="45.52 40.3 44.39 46.16 52.56 54.08 54.61 51.78 45.52 40.3"
    />
    <path
      fill="currentColor"
      d="M26.47,44.1l-10.43,.81-4.57-21.56c-.3-1.13-2.25-1.18-2.06,.86l2.8,24.24h4.5l-4.21,7.39h4.02l4.22-7.39h8.89c.73-1.67-.26-4.74-3.17-4.35"
    />
    <path
      fill="currentColor"
      d="M41.33,33.99c-2.18-.98-12.42,.03-12.42,.03l-2.05-10.06,9.26,3.71,.95-2.87s-13.39-6.94-13.87-7.1l-.17-.06c-.78-.22-1.64-.26-2.56-.01-2.87,.75-3.96,3.48-3.26,6.5l3.95,16,15.55-.75s-.61,15.45-.61,16.61h3l2.31-9.47,1.34-7.41c.12-.3,.77-4.15-1.41-5.13"
    />
  </svg>
);
