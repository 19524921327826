import type { AllHTMLAttributes, FC } from 'react';

export const FeedbackC: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg width="119" height="119" viewBox="0 0 119 119" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.5 111.067C87.9795 111.067 111.067 87.9795 111.067 59.5C111.067 31.0205 87.9795 7.93333 59.5 7.93333C31.0205 7.93333 7.93333 31.0205 7.93333 59.5C7.93333 87.9795 31.0205 111.067 59.5 111.067ZM59.5 119C92.3609 119 119 92.3609 119 59.5C119 26.6391 92.3609 0 59.5 0C26.6391 0 0 26.6391 0 59.5C0 92.3609 26.6391 119 59.5 119Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.7733 53.3908C92.3965 53.8201 93.2168 53.3319 93.2168 52.5152V52.495C93.2168 47.6023 89.6651 43.6333 85.2834 43.6333C80.9018 43.6333 77.3501 47.6023 77.3501 52.495V52.5152C77.3501 53.3319 78.1718 53.8201 78.7935 53.3908C80.6797 52.089 82.9032 51.3358 85.2834 51.3358C87.6651 51.3358 89.8872 52.089 91.7733 53.3908Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.4029 53.3908C38.9455 53.8201 39.6617 53.3319 39.6665 52.5152V52.495C39.6665 47.6023 36.5577 43.6333 32.723 43.6333C28.8896 43.6333 25.7832 47.6023 25.7832 52.495V52.5152C25.7856 53.3319 26.503 53.8201 27.0469 53.3908C28.6978 52.089 30.64 51.3358 32.723 51.3358C34.8073 51.3358 36.7508 52.089 38.4029 53.3908Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.8099 87.2667C49.9507 87.2667 40.1942 83.3924 33.0429 76.6371C32.2403 75.8791 31.7758 74.8484 31.7363 73.7361C31.6955 72.6238 32.085 71.5637 32.831 70.7468C33.6034 69.9033 34.6995 69.4167 35.835 69.4167C36.8745 69.4167 37.8666 69.8137 38.6271 70.5343C44.2797 75.8724 52.0007 78.9326 59.8099 78.9326C67.3415 78.9326 74.8639 76.0543 80.4507 71.0356C81.2033 70.3618 82.1665 69.9915 83.1652 69.9915C84.3401 69.9915 85.4586 70.5035 86.2362 71.3966C86.9638 72.2294 87.327 73.303 87.2586 74.4126C87.1928 75.5222 86.702 76.5409 85.8822 77.2788C78.7152 83.7186 69.456 87.2667 59.8099 87.2667Z"
      fill="currentColor"
    />
  </svg>
);
