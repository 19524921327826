import type { AllHTMLAttributes, FC } from 'react';

export const Trains: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    aria-hidden="true"
    width="296"
    height="182"
    viewBox="0 0 296 182"
    {...props}
  >
    <polygon
      points="256.95 157.75 243.86 157.75 276.35 181 295 181 256.95 157.75"
      fill="currentColor"
    />
    <polygon
      points="201.5 157.85 188.9 157.93 202.1 181 219.9 181 201.5 157.85"
      fill="currentColor"
    />
    <path
      d="M274.82,67c-1.45-4.34-6.64-12.3-14.75-18.41-1.78-4.28-5.13-14.69-11.78-25.13C242.81,14.93,236.79-6,156.35,3.35,133.49,6,122.81,17.52,118.18,27.21c0,0-39,78.95-117.18,90.8v12.25s87-.28,129.88,10.22c26.76,4.65,22.59,10.83,37.33,10.74h73.15c34.48-.88,35.75-25.44,36.37-39.7C278.4,96.67,278.71,79.38,274.82,67ZM26.68,121.55a146.63,146.63,0,0,1-22.34,2v-1.22a131,131,0,0,0,22.34-5.08ZM53,114.82a161.5,161.5,0,0,1-21.89,5.94v-5A159.56,159.56,0,0,0,53,106.1ZM79.27,103a166.91,166.91,0,0,1-21.89,10.18v-9.54A183,183,0,0,0,79.27,89ZM108,82.76a164.64,164.64,0,0,1-24.36,17.72v-15A190,190,0,0,0,108,61.26Zm22.45-41.32c-.35-10.61,4-14.26,13.77-18.6l5.63,14.71A139.86,139.86,0,0,0,130.48,41.44Zm24.59-4.53L149,21a144.89,144.89,0,0,1,27.2-5.36l7.46,19.47C173.92,35.31,164.16,35.89,155.07,36.91Zm50.83,69.47a7.62,7.62,0,1,1,8-7.61A7.83,7.83,0,0,1,205.9,106.38ZM189.05,35l-7.6-19.84a198.5,198.5,0,0,1,34.76.87l7.7,20C213.52,35.29,201.42,34.88,189.05,35Zm43.16,28.54a7.62,7.62,0,1,1,8-7.62A7.83,7.83,0,0,1,232.21,63.54Zm-2.67-27L222,16.78c23.36,4.67,23,9.74,30.06,23.31C247.92,38.73,239.84,37.46,229.54,36.52Zm35.86,69.86a7.62,7.62,0,1,1,8-7.61A7.83,7.83,0,0,1,265.4,106.38Z"
      fill="currentColor"
    />
  </svg>
);
