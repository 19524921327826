import type { AllHTMLAttributes, FC } from 'react';

export const Gym: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.8211 30C38.7064 30 42.6666 33.9603 42.6666 38.8455V88.2764C42.6666 93.1617 38.7064 97.1219 33.8211 97.1219C28.9359 97.1219 24.9756 93.1617 24.9756 88.2764V38.8455C24.9756 33.9603 28.9359 30 33.8211 30Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.1788 30C99.0641 30 103.024 33.9603 103.024 38.8455V88.2764C103.024 93.1617 99.0641 97.1219 94.1788 97.1219C89.2936 97.1219 85.3333 93.1617 85.3333 88.2764V38.8455C85.3333 33.9603 89.2936 30 94.1788 30Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.35 36.2439C114.798 36.2439 117.594 39.0394 117.594 42.4878V84.6342C117.594 88.0826 114.798 90.8781 111.35 90.8781C107.901 90.8781 105.106 88.0826 105.106 84.6342V42.4878C105.106 39.0394 107.901 36.2439 111.35 36.2439Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6504 36.2439C20.0988 36.2439 22.8943 39.0394 22.8943 42.4878V84.6342C22.8943 88.0826 20.0988 90.8781 16.6504 90.8781C13.202 90.8781 10.4065 88.0826 10.4065 84.6342V42.4878C10.4065 39.0394 13.202 36.2439 16.6504 36.2439Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.1626 52.8943C6.46154 52.8943 8.3252 54.758 8.3252 57.0569V70.065C8.3252 72.364 6.46154 74.2276 4.1626 74.2276C1.86366 74.2276 0 72.364 0 70.065V57.0569C0 54.758 1.86366 52.8943 4.1626 52.8943Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M123.837 52.8943C126.136 52.8943 128 54.758 128 57.0569V70.065C128 72.364 126.136 74.2276 123.837 74.2276C121.538 74.2276 119.675 72.364 119.675 70.065V57.0569C119.675 54.758 121.538 52.8943 123.837 52.8943Z"
      fill="currentColor"
    />
    <rect
      x="46.8293"
      y="58.0976"
      width="34.3415"
      height="11.4472"
      fill="currentColor"
    />
  </svg>
);
