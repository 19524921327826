import type { AllHTMLAttributes, FC } from 'react';

export const Questions: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <path
      fill="currentColor"
      d="M38.59,55.01c-1.81,.74-3.63,.99-5.36,.99-4.7,0-8-2.39-8-7.26,0-.82,.17-2.06,.5-3.87l2.79-16.99h-5.59l1.07-5.44h14.92l-5.73,21.83c-.99,4.69,2.76,4.64,2.76,4.64h2.64v6.1Z"
    />
    <path
      fill="currentColor"
      d="M36.21,8c2.97,0,4.86,1.81,4.86,4.37,0,3.46-2.8,5.61-5.61,5.61-2.97,0-4.78-2.06-4.78-4.54,0-3.46,2.72-5.44,5.52-5.44"
    />
  </svg>
);
