import type { AllHTMLAttributes, FC } from 'react';

export const Hotel: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.52,28.63c1.7-.46,2.69-2.29,2.21-4.08-.49-1.8-2.25-2.88-3.96-2.42-1.7,.46-2.69,2.28-2.21,4.08,.48,1.79,2.25,2.88,3.96,2.42"
    />
    <path
      fill="currentColor"
      d="M15.16,33.71h10.35v-7.86c0-.89-.73-1.62-1.62-1.62s-1.62,.72-1.62,1.62v4.62h-7.11c-.89,0-1.62,.72-1.62,1.62s.73,1.62,1.62,1.62"
    />
    <path
      fill="currentColor"
      d="M49.47,29.34c-1.61-2.48-4.96-2.76-6.25-2.82-1.28-.06-15.86-1.28-15.86-1.28v8.45h23.15s.55-1.89-1.04-4.34"
    />
    <path
      fill="currentColor"
      d="M54.67,29.19c-.73,0-1.33,.59-1.33,1.33v5.53H10.67v-13.63c0-.74-.6-1.33-1.33-1.33s-1.33,.6-1.33,1.33v20.49h2.67v-4.19H53.33v4.19h2.67v-12.39c0-.74-.6-1.33-1.33-1.33"
    />
  </svg>
);
