import type { AllHTMLAttributes, FC } from 'react';

export const Arrival: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 203 182" {...props}>
    <path fill="currentColor" d="M92 166h110v15H92z" />
    <path
      d="M107.5 91.8a4.4 4.4 0 104.4 4.3v-6.6c9.3 1.4 22.6.7 24-4.2S122.5 69 113.8 66.5l-25.3-7.3L74.9 4.6 62.4 1 61 51.3 25.5 41.1l-5.4-22L9.4 16 3.3 37.5c-.9 3-3.6 8.8-1.5 11.7 10.9 14.5 53.1 23.5 53.1 23.5l-25.5 43.7 12.4 3.6 40.4-38.8 25.3 7.5z"
      fillRule="evenodd"
      fill="currentColor"
    />
  </svg>
);
