import type { AllHTMLAttributes, FC } from 'react';

export const MGates: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 369.57789 182" {...props}>
    <path
      d="M91.514,72.17524q4.37565,13.92969,8.77229,29.91605,4.37565,15.9916,8.24762,30.94439h.50367q4.12382-14.95278,8.77229-30.94439,4.659-15.9916,9.02412-29.916L150.04487,1h65.49831V181H174.80873V90.73767q0-9.79014.12592-20.11542.12591-10.30956.25183-20.49318t.39874-19.213h-.52465c-1.3851,4.98426-2.95908,10.56664-4.7639,16.75761s-3.65162,12.34-5.54039,18.43652q-2.86462,9.16054-5.41447,17.54459-2.58132,8.37357-4.659,14.05037L125.55383,181H87.90437L59.53086,97.96747Q58.492,94.86673,55.79529,86.2256q-2.70723-8.62539-5.93913-18.82476-3.2109-10.18364-6.191-20.24134-2.95908-10.05773-4.7639-16.24345h-.52465q.2833,9.0189.39874,19.213.12591,10.18362.25183,20.49318.12593,10.32528.27282,20.11542.12592,9.80588.12592,18.05876V181H1V1H69.07964Z"
      fill="currentColor"
    />
    <path
      id="plane"
      d="M306.20813,6.52714,299.009,9.161l16.85643,24.5823-20.6023,7.55028L284.962,32.6312l-6.2041,2.22412,4.50675,12.40821c.70235,1.81441,1.40471,5.50175,3.3947,6.14557,10.30116,3.16059,33.65434-7.25763,33.71287-7.25763l2.985,29.84994,7.19911-2.63382,5.85293-32.65934,14.69085-5.3847c5.09205-1.8144,13.75438-8.01851,12.52526-11.41321s-12.057-3.16058-17.14908-1.28764c0,0-14.63232,5.38469-14.69085,5.32616Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <rect
      x="249.59712"
      y="110.41663"
      width="64.38221"
      height="8.77939"
      fill="currentColor"
    />
    <path d="M366,3V118H251V3H366m2-2H249V120H368V1Z" fill="currentColor" />
    <path
      d="M275.59721,155.03044a15.10146,15.10146,0,0,0-3.74618-1.2853,21.96538,21.96538,0,0,0-4.7583-.50382,13.14036,13.14036,0,0,0-4.64186.777,9.97432,9.97432,0,0,0-3.51554,2.19665,9.74012,9.74012,0,0,0-2.25264,3.47747,12.47219,12.47219,0,0,0-.80387,4.57692,13.39048,13.39048,0,0,0,.73446,4.53214,9.25441,9.25441,0,0,0,5.61143,5.74354,13.0993,13.0993,0,0,0,4.74038.79939,17.40011,17.40011,0,0,0,1.99961-.1142,10.53823,10.53823,0,0,0,1.77121-.34259v-7.93572h-6.8318v-5.13448h12.97616v17.197a28.15714,28.15714,0,0,1-5.3181,1.30545,38.25093,38.25093,0,0,1-5.45693.38962,20.108,20.108,0,0,1-6.81164-1.12184,15.63594,15.63594,0,0,1-5.43454-3.25579,14.85319,14.85319,0,0,1-3.576-5.18374A17.64462,17.64462,0,0,1,249,164.247a16.93368,16.93368,0,0,1,1.28306-6.71759,14.29625,14.29625,0,0,1,3.64542-5.11433,16.18642,16.18642,0,0,1,5.71-3.2334,23.58239,23.58239,0,0,1,7.52147-1.12184,39.06366,39.06366,0,0,1,4.653.27319,20.2306,20.2306,0,0,1,4.24328.96509Z"
      fill="currentColor"
    />
    <path
      d="M284.12632,158.05559a15.57666,15.57666,0,0,1,4.07982-1.37487,22.55265,22.55265,0,0,1,4.4045-.459,14.1811,14.1811,0,0,1,4.70008.68743,8.17517,8.17517,0,0,1,3.164,1.92571,7.56816,7.56816,0,0,1,1.78912,3.0274,13.07944,13.07944,0,0,1,.57324,3.99025q0,.68854-.02464,1.6055-.02351.917-.02239,1.90332c0,.65608-.00672,1.292-.02239,1.90108-.01567.61354-.02239,1.14646-.02239,1.6055q0,2.10933.1142,4.21865.11419,2.11267.206,3.30281h-5.36512a7.20022,7.20022,0,0,1-.06941-.82626c-.01568-.365-.02239-.71654-.02239-1.05466,0-.27542.00671-.59563.02239-.96286s.03806-.73221.06941-1.09944h-.0918a9.08765,9.08765,0,0,1-3.164,3.20877,9.43439,9.43439,0,0,1-4.95311,1.19349,9.6168,9.6168,0,0,1-3.18638-.50382,7.10917,7.10917,0,0,1-2.47656-1.44652,6.6644,6.6644,0,0,1-1.62789-2.29294,7.57687,7.57687,0,0,1-.59787-3.09457,6.37873,6.37873,0,0,1,3.1416-5.70995q3.14047-1.99513,9.01277-1.99513.73222,0,1.6279.02463.89344.02352,1.71971.1142a5.2428,5.2428,0,0,0-1.33009-3.99025,6.052,6.052,0,0,0-4.22089-1.23827,12.40989,12.40989,0,0,0-3.75961.59562,16.77761,16.77761,0,0,0-3.43941,1.5137Zm13.0702,11.46469a7.99766,7.99766,0,0,0-1.07929-.09181h-.94046a14.42372,14.42372,0,0,0-5.59352.84866,3.26878,3.26878,0,0,0-.96286,5.29794,4.0349,4.0349,0,0,0,2.70495.824,6.629,6.629,0,0,0,2.27055-.38962,4.95726,4.95726,0,0,0,1.88093-1.21588,5.75192,5.75192,0,0,0,1.26067-2.13172A9.559,9.559,0,0,0,297.19652,169.52028Z"
      fill="currentColor"
    />
    <path
      d="M306.04808,161.31138v-4.53885h4.63066v-4.9531l5.77937-1.97274v6.92584h5.64055v4.53885h-5.64055v9.21878a8.25407,8.25407,0,0,0,.70983,3.94322q.71206,1.23941,2.958,1.23828a7.0271,7.0271,0,0,0,2.24816-.36723l.13659,4.72471a8.97645,8.97645,0,0,1-1.9481.45679,17.923,17.923,0,0,1-2.27055.13883q-4.08094,0-5.89357-2.10932-1.81039-2.10933-1.81152-6.787v-10.457Z"
      fill="currentColor"
    />
    <path
      d="M331.45179,170.29952a5.49614,5.49614,0,0,0,2.08693,4.32389,8.09512,8.09512,0,0,0,5.20614,1.59207,13.06511,13.06511,0,0,0,3.6902-.50382,12.75833,12.75833,0,0,0,3.18862-1.42189l.09181,5.07626a14.91732,14.91732,0,0,1-3.48643,1.15766,20.38922,20.38922,0,0,1-4.21865.4165,16.02205,16.02205,0,0,1-5.27332-.82627,10.62289,10.62289,0,0,1-6.65042-6.35036,14.31123,14.31123,0,0,1-.91583-5.29794,13.76748,13.76748,0,0,1,.84865-4.90608,11.2608,11.2608,0,0,1,2.40714-3.876,11.03845,11.03845,0,0,1,3.73723-2.54373,12.35108,12.35108,0,0,1,4.83891-.91807q5.13559,0,7.74986,3.09457,2.61315,3.09681,2.61315,9.51659c0,.18361-.00672.43664-.02239.75685a5.15355,5.15355,0,0,1-.06942.70982Zm10.27345-4.21865a5.68674,5.68674,0,0,0-1.33009-4.19625,4.94417,4.94417,0,0,0-3.623-1.30545,5.33361,5.33361,0,0,0-3.60063,1.37486,5.85852,5.85852,0,0,0-1.80928,4.12684Z"
      fill="currentColor"
    />
    <path
      d="M351.35379,174.84061a16.9493,16.9493,0,0,0,3.417,1.21589,14.23565,14.23565,0,0,0,3.2334.38962,5.59209,5.59209,0,0,0,2.705-.62026,2.02737,2.02737,0,0,0,1.14647-1.90332,2.569,2.569,0,0,0-.206-1.05466,2.48848,2.48848,0,0,0-.71206-.87105,6.02053,6.02053,0,0,0-1.39726-.80163c-.59787-.25975-1.36816-.55756-2.31758-.89568a11.815,11.815,0,0,1-4.83667-2.68256,5.81105,5.81105,0,0,1-1.44428-4.14923,6.68084,6.68084,0,0,1,.665-3.02739,6.41877,6.41877,0,0,1,1.90332-2.27055,9.114,9.114,0,0,1,2.958-1.44428,13.58547,13.58547,0,0,1,3.829-.50382,16.98026,16.98026,0,0,1,6.41979,1.10168l-.50382,4.58588a13.65463,13.65463,0,0,0-5.64054-1.33008,4.702,4.702,0,0,0-2.56837.64041,2.05346,2.05346,0,0,0-1.00987,1.83614,2.39443,2.39443,0,0,0,.206,1.03,2.07767,2.07767,0,0,0,.68744.78148,6.79307,6.79307,0,0,0,1.33008.68743q.84978.3426,2.08693.75685a20.07838,20.07838,0,0,1,2.79676,1.12184,8.74858,8.74858,0,0,1,2.11157,1.44652,5.55168,5.55168,0,0,1,1.32784,1.92571,6.74066,6.74066,0,0,1,.459,2.61314,7.05912,7.05912,0,0,1-.70983,3.2334,6.82789,6.82789,0,0,1-1.97273,2.36236,8.71861,8.71861,0,0,1-3.04979,1.44428,15.17173,15.17173,0,0,1-3.94323.48143,23.17412,23.17412,0,0,1-3.73722-.29782,19.34156,19.34156,0,0,1-3.55361-.94046Z"
      fill="currentColor"
    />
  </svg>
);
