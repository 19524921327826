import type { AllHTMLAttributes, FC } from 'react';

export const Gps: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_221_4221)">
      <path
        d="M8.00033 5.33329C6.52699 5.33329 5.33366 6.52663 5.33366 7.99996C5.33366 9.47329 6.52699 10.6666 8.00033 10.6666C9.47366 10.6666 10.667 9.47329 10.667 7.99996C10.667 6.52663 9.47366 5.33329 8.00033 5.33329ZM13.9603 7.33329C13.6537 4.55329 11.447 2.34663 8.66699 2.03996V0.666626H7.33366V2.03996C4.55366 2.34663 2.34699 4.55329 2.04033 7.33329H0.666992V8.66663H2.04033C2.34699 11.4466 4.55366 13.6533 7.33366 13.96V15.3333H8.66699V13.96C11.447 13.6533 13.6537 11.4466 13.9603 8.66663H15.3337V7.33329H13.9603V7.33329ZM8.00033 12.6666C5.42033 12.6666 3.33366 10.58 3.33366 7.99996C3.33366 5.41996 5.42033 3.33329 8.00033 3.33329C10.5803 3.33329 12.667 5.41996 12.667 7.99996C12.667 10.58 10.5803 12.6666 8.00033 12.6666Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_221_4221">
        <rect width="16" height="16" fill="transparent" />
      </clipPath>
    </defs>
  </svg>
);
