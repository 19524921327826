import type { AllHTMLAttributes, FC } from 'react';

export const LostFound: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 196 182" {...props}>
    <path fill="currentColor" d="M33 151h30v30H33z" />
    <path
      d="M3.7 36c11.6-5.3 24.4-9.7 37.2-9.7s27.8 6.1 27.8 22.1c0 12.6-8.9 19.9-17.9 29.9S33.2 100.9 33.2 124h29.7C60 92.2 104 78.5 104 44.5 104 13.4 76.7 1 48.9 1 32.7 1 16.5 4.4 1 9zm90.7 64.4v75.7a4.3 4.3 0 008.5 0v-29.7h4.3v29.7a4.3 4.3 0 008.5 0v-29.7h4.3v29.7a4.3 4.3 0 008.5 0v-29.7h4.3v29.7a4.3 4.3 0 008.5 0v-38.2h2.6s-1.7-4 8.1 19.6a5 5 0 006.1 2.6 4.7 4.7 0 002.6-6.2c-10.4-25.1-22.4-53.5-22.4-53.5h-14.8v9.9c0 7.6-11.3 7.6-11.3 0v-9.9zm79.2-79.5a7.3 7.3 0 0114.5 0h5.7a13 13 0 00-25.9 0v13.7H147l21.6 99.4v14h5.7v-14L195 34.6h-21.4z"
      fillRule="evenodd"
      fill="currentColor"
    />
  </svg>
);
