import type { AllHTMLAttributes, FC } from 'react';

export const LuggageClaim: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    aria-hidden="true"
    focusable="false"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    {...props}
  >
    <g fill="currentColor">
      <circle cx="7.7" cy="40.8" r="2.3" />
      <circle cx="14.2" cy="40.8" r="2.3" />
      <circle cx="20.7" cy="40.8" r="2.3" />
      <circle cx="27.2" cy="40.8" r="2.3" />
      <circle cx="33.7" cy="40.8" r="2.3" />
      <circle cx="40.2" cy="40.8" r="2.3" />
      <rect x="5" y="35.5" width="38" height="1.5" />
      <path d="M10,33h2V11.5H10a2,2,0,0,0-2,2V31A2,2,0,0,0,10,33Z" />
      <path d="M40,31V13.5a2,2,0,0,0-2-2H36V33h2A2,2,0,0,0,40,31Z" />
      <path d="M33.5,11.5h-4V7a2,2,0,0,0-2-2h-7a2,2,0,0,0-2,2v4.5h-4V33h19Zm-5.5,0H20V7a.5.5,0,0,1,.5-.5h7A.5.5,0,0,1,28,7Z" />
    </g>
  </svg>
);
