import type { AllHTMLAttributes, FC } from 'react';

export const PlaneSpotting: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 76.5332 76.5332" {...props}>
    <path
      d="M16.5869,8.0459l-2.479.9033,5.79941,8.4634L12.8164,19.99369,9.28031,17.0171c.03129-.0132-2.15141.7812-2.13571.7759.664,1.8203.9272,2.5444,1.5546,4.268.2217.6119.4742,1.89069,1.1617,2.11969C13.397,25.2564,21.4414,21.6704,21.4571,21.665l1.018,10.2735,2.482-.9009,2.0205-11.2393c-.0259.0259,5.0522-1.8388,5.0522-1.8388,1.7603-.6406,4.7319-2.7735,4.3101-3.9322-.3882-1.0678-4.1563-1.0805-5.9038-.4477,0,0-5.0313,1.8516-5.0523,1.8408Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M42.0762,32.7798A5.1797,5.1797,0,1,1,47.253,37.959,5.18,5.18,0,0,1,42.0762,32.7798Z"
      fill="currentColor"
    />
    <rect x="1" y="49" width="32" height="3" fill="currentColor" />
    <rect x="55" y="49" width="21" height="3" fill="currentColor" />
    <path
      d="M61.9561,27.6914l-3.252,9.28369a2.30978,2.30978,0,0,1-1.26849,1.34912l-4.41407,1.544h-12.979c-3.06744,0-4.62744,4.4375-4.62744,7.50488V66.92291h4.4165V48.16021h1.46875V76.06347h4.90674V66.92291H48.1699v9.14056h4.90631V45.98828l6.23926-2.33106a4.00274,4.00274,0,0,0,2.85443-2.85455l3.961-11.67377Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
