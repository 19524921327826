import type { AllHTMLAttributes, FC } from 'react';

export const Pod: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <path
      fill="currentColor"
      d="M16.36,40.99c1.59-.43,2.51-2.13,2.05-3.79-.45-1.67-2.09-2.67-3.68-2.25-1.59,.43-2.5,2.13-2.05,3.8,.44,1.67,2.1,2.67,3.68,2.25"
    />
    <path
      fill="currentColor"
      d="M21.31,41.09h7.15v-7.3c0-.83-.68-1.51-1.5-1.51s-1.51,.68-1.51,1.51v4.29h-4.13c-.83,0-1.51,.68-1.51,1.51s.68,1.51,1.51,1.51"
    />
    <path
      fill="currentColor"
      d="M50.72,37.05c-1.5-2.3-4.61-2.56-5.81-2.61-1.19-.06-14.74-1.2-14.74-1.2v7.85h21.51s.52-1.76-.96-4.03"
    />
    <rect fill="currentColor" x="8.02" y="53.64" width="47.96" height="2.36" />
    <rect fill="currentColor" x="8.02" y="8" width="47.96" height="2.36" />
    <rect fill="currentColor" x="8.02" y="8" width="2.36" height="48" />
    <rect fill="currentColor" x="53.62" y="8" width="2.36" height="48" />
    <path
      fill="currentColor"
      d="M46.25,23.29c-1.43,0-2.77-.56-3.77-1.56-1.01-1.02-1.56-2.36-1.56-3.78s.55-2.76,1.56-3.77c1.01-1,2.35-1.56,3.77-1.56s2.76,.56,3.77,1.56c1.01,1.01,1.56,2.35,1.56,3.77s-.56,2.77-1.56,3.78c-1.01,1-2.35,1.56-3.77,1.56m0-9.81c-2.47,0-4.47,2-4.47,4.47s2,4.47,4.47,4.47,4.47-2,4.47-4.47-2-4.47-4.47-4.47"
    />
    <polygon
      fill="currentColor"
      points="45.92 18.83 45.92 14.01 46.58 14.01 46.58 17.24 48.25 15.57 48.71 16.04 45.92 18.83"
    />
    <rect fill="currentColor" x="12.34" y="43.17" width="39.38" height="5.71" />
    <rect fill="currentColor" x="12.34" y="48.24" width="2.44" height="3.08" />
    <rect fill="currentColor" x="49.28" y="48.24" width="2.44" height="3.08" />
  </svg>
);
