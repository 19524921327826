import type { AllHTMLAttributes, FC } from 'react';

export const Wifi: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 20 16" {...props}>
    <path
      d="M9.99991 15.1006C10.9803 15.1006 11.7751 14.3058 11.7751 13.3254C11.7751 12.3451 10.9803 11.5503 9.99991 11.5503C9.01952 11.5503 8.22476 12.3451 8.22476 13.3254C8.22476 14.3058 9.01952 15.1006 9.99991 15.1006Z"
      fill="currentColor"
    />
    <path
      d="M9.99992 8C9.16727 8.00214 8.347 8.20169 7.60642 8.58227C6.86584 8.96284 6.22605 9.5136 5.73956 10.1893L7.15968 11.2544C7.41958 10.8762 7.75271 10.5539 8.13935 10.3067C8.52599 10.0594 8.95829 9.89224 9.41068 9.815C9.86306 9.73776 10.3263 9.75204 10.7731 9.857C11.2199 9.96195 11.6411 10.1554 12.0118 10.426C12.3305 10.656 12.6102 10.9357 12.8402 11.2544L14.2603 10.1893C13.7738 9.5136 13.134 8.96284 12.3934 8.58227C11.6528 8.20169 10.8326 8.00214 9.99992 8Z"
      fill="currentColor"
    />
    <path
      d="M10 4.44971C8.6079 4.45199 7.23558 4.77953 5.9925 5.40621C4.74942 6.03289 3.66997 6.94136 2.84024 8.05917L4.26036 9.12426C4.81582 8.36535 5.51652 7.72435 6.32176 7.23847C7.12699 6.75259 8.02073 6.43152 8.95108 6.29389C9.88143 6.15627 10.8298 6.20483 11.7413 6.43677C12.6527 6.6687 13.509 7.07939 14.2604 7.64497C14.8249 8.06098 15.3236 8.55967 15.7396 9.12426L17.1598 8.05917C16.33 6.94136 15.2506 6.03289 14.0075 5.40621C12.7644 4.77953 11.3921 4.45199 10 4.44971Z"
      fill="currentColor"
    />
    <path
      d="M9.99994 0.899455C8.04909 0.894429 6.12499 1.35322 4.38631 2.23799C2.64764 3.12276 1.14418 4.40817 -6.10352e-05 5.98821L1.42006 6.99413C3.12641 4.73431 5.65416 3.23749 8.45604 2.82773C11.2579 2.41797 14.1084 3.12825 16.3905 4.80478C17.2203 5.42715 17.9575 6.1643 18.5798 6.99413L19.9999 5.98821C18.8557 4.40817 17.3522 3.12276 15.6136 2.23799C13.8749 1.35322 11.9508 0.894429 9.99994 0.899455Z"
      fill="currentColor"
    />
  </svg>
);
