import type { AllHTMLAttributes, FC } from 'react';

export const TelephoneCall: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    aria-hidden="true"
    height="222"
    width="222"
    viewBox="0 0 222 222"
    {...props}
  >
    <path
      d="m90.9 131.1a160.2 160.2 0 0 0 42 30 14.6 14.6 0 0 0 18.1-2.9l4.1-4.1 6.2-6.2a5.7 5.7 0 0 0 .1-8.1 1.2 1.2 0 0 0 -.5-.5c-.4-.4-.8-.4-.8-.8l-21-13.6a6.2 6.2 0 0 0 -7.9.8l-7 7a4.4 4.4 0 0 1 -5.7.4 122.7 122.7 0 0 1 -15.7-14 129.8 129.8 0 0 1 -14-15.6 4.7 4.7 0 0 1 .4-5.8l7-7a6.1 6.1 0 0 0 .9-7.8l-13.6-21a6.7 6.7 0 0 0 -8.7-2.1c-.4.4-.8.4-.8.9l-6.2 6.1-4.1 4.1a15.1 15.1 0 0 0 -2.9 18.2 162.2 162.2 0 0 0 30.1 42z"
      fill="currentColor"
    />
  </svg>
);
