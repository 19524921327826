import type { AllHTMLAttributes, FC } from 'react';

export const Food: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="currentColor">
      <path d="M41.4,5a1.6,1.6,0,0,0-1.6,1.4L37.1,24.8v.4A2.8,2.8,0,0,0,39.8,28h.7V41.8A1.2,1.2,0,0,0,41.7,43h.1A1.2,1.2,0,0,0,43,41.8h0V6.6A1.6,1.6,0,0,0,41.4,5Z" />
      <path d="M32,13.8A1.2,1.2,0,0,1,30.8,15h0a1.2,1.2,0,0,1-1.3-1.1h0V5H28v8.8A1.2,1.2,0,0,1,26.8,15h0a1.2,1.2,0,0,1-1.3-1.1h0V5H24V15.5h0a4.8,4.8,0,0,0,3.5,4.4V41.7A1.2,1.2,0,0,0,28.6,43h0A1.4,1.4,0,0,0,30,41.8h0V19.9a4.7,4.7,0,0,0,3.5-4.1V5H32Z" />
      <path d="M13.5,14.3V11a2,2,0,0,0-2-2H5v1.5h6.5a.5.5,0,0,1,.5.5v3.3A7.7,7.7,0,0,0,5,22H5L6,43H19.5l1-20.9h0A7.7,7.7,0,0,0,13.5,14.3ZM18,41.5H7.4v-3H18.2Zm.8-15H6.7l-.2-3H18.9ZM6.5,22a6.2,6.2,0,0,1,6.2-6.2h.1A6.2,6.2,0,0,1,19,22Z" />
    </g>
  </svg>
);
