import type { AllHTMLAttributes, FC } from 'react';

export const Departure: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 203 182" {...props}>
    <path
      d="M103.7 1L91.4 5.5l28.8 42L85 60.4 67.4 45.6l-10.6 3.8 7.7 21.2c1.2 3.1 2.4 9.4 5.8 10.5 17.6 5.4 57.5-12.4 57.6-12.4l5.1 51 12.3-4.5 10-55.8 25.1-9.2c8.7-3.1 23.5-13.7 21.4-19.5s-20.6-5.4-29.3-2.2c0 0-25 9.2-25.1 9.1z"
      fillRule="evenodd"
      fill="currentColor"
    />
    <path fill="currentColor" d="M1 166h110v15H1z" />
  </svg>
);
