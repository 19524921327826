import type { AllHTMLAttributes, FC } from 'react';

export const Buses: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    aria-hidden="true"
    width="145"
    height="182"
    viewBox="0 0 145 182"
    {...props}
  >
    <path
      d="M141.66,26.89c-.13-3.42-1.3-19.85-1.3-19.85a3,3,0,0,0-2.67-2.63S107.32,1,92.28,1H52.72C38,1,7.31,4.41,7.31,4.41A3,3,0,0,0,4.64,7S3.48,23.38,3.35,26.89L1,91.94v69.45H6.62v16.08c-.08,1.37,1,3.57,2,3.53H21.14c.94,0,2.35-2.16,2.35-3.53V161.39h98v16.08c0,1.37,1.41,3.53,2.35,3.53h12.56c.94,0,2-2.16,2-3.53V161.39H144V91.94ZM47.78,9.47a2.35,2.35,0,0,1,2.35-2.35H125.5a2.35,2.35,0,0,1,2.35,2.35V21.65A2.35,2.35,0,0,1,125.5,24H50.13a2.35,2.35,0,0,1-2.35-2.35ZM21.69,144.29A11.26,11.26,0,1,1,32.94,133,11.25,11.25,0,0,1,21.69,144.29Zm101.61,0A11.26,11.26,0,1,1,134.55,133,11.25,11.25,0,0,1,123.3,144.29Zm10.57-51.92H11.33a1.8,1.8,0,0,1-1.76-1.76L11.66,31.4a1.8,1.8,0,0,1,1.76-1.77H131.65a1.8,1.8,0,0,1,1.76,1.77l2.22,59.21A1.8,1.8,0,0,1,133.87,92.37Z"
      fill="currentColor"
    />
  </svg>
);
