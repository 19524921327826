import type { AllHTMLAttributes, FC } from 'react';

export const Shuttles: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    aria-hidden="true"
    width="256"
    height="182"
    viewBox="0 0 256 182"
    {...props}
  >
    <path
      d="M34.29,158.14a23,23,0,1,0,23-22.86A22.94,22.94,0,0,0,34.29,158.14Z"
      fill="currentColor"
    />
    <path
      d="M184.88,158.14a23,23,0,1,0,23-22.86A22.94,22.94,0,0,0,184.88,158.14Z"
      fill="currentColor"
    />
    <path
      d="M253.24,86.75c-3.71-6.31-38-65.16-38-65.16V6.12c-2-8.17-7.42-5.34-13.93,0L181.15,21.59H17.43A16.39,16.39,0,0,0,1,37.94V161.42H24.43c-.11-1.08,0-4.38,0-4.38a32.87,32.87,0,0,1,65.72,1.1c0,1.1.1,2.2,0,3.28H175A32.93,32.93,0,1,1,240.74,158v3.38H255V95.24S255.34,90.32,253.24,86.75ZM64,80.41c0,4.51-3.33,8.16-7.42,8.16H22.44c-4.1,0-7.42-3.65-7.42-8.16V39.56c0-4.51,3.32-8.17,7.42-8.17h34.1c4.09,0,7.42,3.66,7.42,8.17Zm60.18,0c0,4.51-3.32,8.16-7.42,8.16H82.61c-4.09,0-7.42-3.65-7.42-8.16V39.56c0-4.51,3.33-8.17,7.42-8.17h34.11c4.1,0,7.42,3.66,7.42,8.17Zm60.18,0c0,4.51-3.33,8.16-7.41,8.16H142.79c-4.1,0-7.41-3.65-7.41-8.16V39.56c0-4.51,3.31-8.17,7.41-8.17h34.12c4.08,0,7.41,3.66,7.41,8.17ZM231,88.05H202.39a8.19,8.19,0,0,1-8.21-8.17V39a8.18,8.18,0,0,1,8.21-8.16h5c4.54,0,5.45,2.92,8.47,8.16l23.29,40.84C242.52,85.89,235.51,88.05,231,88.05Z"
      fill="currentColor"
    />
  </svg>
);
