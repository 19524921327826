import type { AllHTMLAttributes, FC } from 'react';

export const Shop: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <path
      fill="currentColor"
      d="M49.19,39.15c.59,0,1.11-.38,1.28-.94l5.46-17.38c.13-.41,.06-.86-.2-1.2-.25-.35-.66-.55-1.09-.55H20.73c-.27,0-.53,.08-.74,.22l-1.87-4.68c-.16-.38-.48-.68-.88-.79l-7.52-2.16c-.71-.21-1.46,.21-1.67,.92-.2,.72,.21,1.46,.92,1.67l6.89,1.98,8.27,22-3.01,5.64c-.11,0-.23-.03-.35-.03-2.36,0-4.28,1.92-4.28,4.29s1.92,4.28,4.28,4.28c1.8,0,3.34-1.12,3.97-2.69h18.6c.64,1.58,2.17,2.69,3.97,2.69,2.36,0,4.29-1.92,4.29-4.28s-1.92-4.29-4.29-4.29c-1.8,0-3.34,1.12-3.97,2.69H24.75c-.21-.53-.53-1.01-.93-1.42l3.18-5.96h22.19Zm-28.41,11.3c-1.29,0-2.34-1.05-2.34-2.34s1.05-2.34,2.34-2.34,2.34,1.05,2.34,2.34-1.05,2.34-2.34,2.34m26.55-4.68c1.29,0,2.34,1.05,2.34,2.34s-1.05,2.34-2.34,2.34-2.34-1.05-2.34-2.34,1.05-2.34,2.34-2.34m-3.86-24.01h9.35l-1.12,3.57s-.04-.01-.07-.01h-8.16v-3.56Zm0,5.41h7.65l-1.19,3.77h-6.46v-3.77Zm0,5.63h5.88l-1.15,3.65h-4.73v-3.65Zm-11.49-11.04h9.64v3.55h-9.64v-3.55Zm0,5.41h9.64v3.77h-9.64v-3.77Zm0,5.63h9.64v3.65h-9.64v-3.65Zm-11-11.04h9.14v3.56h-7.72l-1.42-3.56Zm2.16,5.41h6.98v3.77h-5.47l-1.51-3.77Zm2.25,5.63h4.73v3.65h-3.27l-1.46-3.65Z"
    />
  </svg>
);
