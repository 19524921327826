import type { AllHTMLAttributes, FC } from 'react';

export const ArrowLeft: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" width="48" height="48" viewBox="0 0 48 48" {...props}>
    <polygon
      fill="currentColor"
      points="23.5 46.5 29 40.6 16.4 27.9 47 27.9 47 20.1 16.4 20.1 29 7.4 23.5 1.5 1 24 23.5 46.5"
    />
  </svg>
);
