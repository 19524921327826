import type { AllHTMLAttributes } from 'react';

export const PrmFlyRight = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M49 28.6235C48.9377 29.5302 48.5351 30.3791 47.8739 30.9984C47.2126 31.6177 46.3421 31.961 45.4386 31.9588H34.2632L24.193 47.5235C23.8161 48.131 23.291 48.6315 22.6677 48.9774C22.0444 49.3234 21.3435 49.5032 20.6316 49.5H17.4386L22.8421 31.9588H14.8596L11.6667 35.9118C11.487 36.2011 11.2314 36.435 10.928 36.5876C10.6247 36.7401 10.2853 36.8055 9.94736 36.7765H7L9.21052 28.5L7 20.2235H9.94736C10.2853 20.1945 10.6247 20.2599 10.928 20.4124C11.2314 20.565 11.487 20.7989 11.6667 21.0883L14.8596 25.0412H22.8421L17.4386 7.50004H20.6316C21.3435 7.49677 22.0444 7.67664 22.6677 8.02257C23.291 8.3685 23.8161 8.869 24.193 9.47651L34.2632 25.0412H45.5614C46.4636 25.072 47.3206 25.4463 47.9589 26.0884C48.5973 26.7305 48.9694 27.5925 49 28.5V28.6235Z"
      fill="currentColor"
    />
  </svg>
);
