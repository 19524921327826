import type { AllHTMLAttributes, FC } from 'react';

export const FeedbackB: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg width="119" height="119" viewBox="0 0 119 119" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.5 111.067C87.9795 111.067 111.067 87.9795 111.067 59.5C111.067 31.0205 87.9795 7.93333 59.5 7.93333C31.0205 7.93333 7.93333 31.0205 7.93333 59.5C7.93333 87.9795 31.0205 111.067 59.5 111.067ZM59.5 119C92.3609 119 119 92.3609 119 59.5C119 26.6391 92.3609 0 59.5 0C26.6391 0 0 26.6391 0 59.5C0 92.3609 26.6391 119 59.5 119Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.4757 91.2333C44.7837 91.2333 31.0889 82.5267 27.9129 70.5312C27.5831 69.2973 27.8183 68.0063 28.562 66.9957C29.2848 66.0138 30.3846 65.45 31.579 65.45H89.3777C90.5682 65.45 91.7127 66.0329 92.4406 67.0121C93.1476 67.964 93.3867 69.1639 93.0963 70.3051C90.0281 82.4314 76.3097 91.2333 60.4757 91.2333Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.4946 75.3667C69.1181 75.3667 77.0257 73.1331 83.3003 69.4167H37.6836C43.9581 73.1331 51.8671 75.3667 60.4946 75.3667Z"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.6095 81.3167C56.2617 81.3167 52.3534 83.0622 49.584 85.848C52.8643 86.7611 56.4019 87.2667 60.0949 87.2667C64.0898 87.2667 67.8949 86.6658 71.4007 85.6018C68.6452 82.9577 64.8299 81.3167 60.6095 81.3167Z"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.1723 51.5667C88.5472 51.5667 87.9503 51.3732 87.4476 51.0112C86.7648 50.5152 86.0341 50.2651 85.2752 50.2651C84.529 50.2651 83.8054 50.5152 83.1268 51.0098C82.6241 51.3732 82.0272 51.5667 81.3993 51.5667C80.1856 51.5667 79.0678 50.8579 78.551 49.7594C77.7654 48.1111 77.3501 46.1822 77.3501 44.1843C77.3501 38.4116 80.9051 33.7167 85.2752 33.7167C89.6567 33.7167 93.2201 38.4116 93.2201 44.1843C93.2201 46.1795 92.8048 48.1083 92.0177 49.7636C91.4996 50.8579 90.3832 51.5667 89.1723 51.5667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.5875 51.5667C38.961 51.5667 38.3641 51.3732 37.8615 51.0098C37.1815 50.5152 36.4508 50.2651 35.692 50.2651C34.9416 50.2651 34.2193 50.5138 33.5436 51.0085C33.041 51.3732 32.444 51.5667 31.8147 51.5667C30.6039 51.5667 29.4861 50.8579 28.9666 49.7636C28.1824 48.1055 27.7671 46.1781 27.7671 44.1843C27.7671 38.4116 31.3234 33.7167 35.692 33.7167C40.0719 33.7167 43.6338 38.4116 43.6338 44.1843C43.6338 46.1739 43.2198 48.1028 42.4371 49.7608C41.9133 50.8592 40.7955 51.5667 39.5875 51.5667Z"
      fill="currentColor"
    />
  </svg>
);
