import type { AllHTMLAttributes, FC } from 'react';

export const NoSmoking: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" {...props}>
    <g clipPath="url(#clip0_2_34)">
      <rect width="96" height="96" fill="none" />
      <path
        d="M17.0143 47.0339H14.875V59.8698H17.0143V47.0339Z"
        fill="currentColor"
      />
      <path
        d="M21.2929 47.0339H19.1536V59.8698H21.2929V47.0339Z"
        fill="currentColor"
      />
      <path
        d="M83.3333 47.0339H23.4323V59.8698H83.3333V47.0339Z"
        fill="currentColor"
      />
      <path
        d="M26.9835 35.2248C28.618 34.7331 30.2935 34.3894 31.9895 34.198C36.2682 33.5562 40.5468 32.8716 40.5468 25.6407H38.4075C38.4075 30.989 35.7975 31.374 31.5189 32.0586C29.7515 32.2867 28.0057 32.6588 26.2989 33.1711C19.1536 35.5243 19.1536 44.5095 19.1536 44.8946H21.2929C21.2929 44.8946 21.3785 37.1074 26.9835 35.2248Z"
        fill="currentColor"
      />
      <path
        d="M22.8333 31.9302C29.8502 28.4218 29.8502 25.6406 29.8502 17.0833H27.7109C27.7109 25.6406 27.7109 27.1382 21.8919 29.9193C14.875 33.5133 14.875 44.4239 14.875 44.8945H17.0143C17.0143 44.8945 17.0999 34.8397 22.8333 31.9302Z"
        fill="currentColor"
      />
      <path
        d="M68.8261 34.5L64.2609 30L47.5217 46.8L30.7826 30L26.2174 34.5L43.2609 51L26.2174 67.5L30.7826 72L47.5217 55.2L64.2609 72L68.8261 67.5L51.7826 51L68.8261 34.5Z"
        style={{ fill: 'var(--color-error, red)' }}
      />
    </g>
    <defs>
      <clipPath id="clip0_2_34">
        <rect width="96" height="96" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
