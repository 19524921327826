import type { AllHTMLAttributes, FC } from 'react';

export const Spinner: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    aria-hidden="true"
    height="202"
    width="202"
    viewBox="0 0 202 202"
    {...props}
  >
    <clipPath id="spinner-arc">
      <path d="M100,0 A100,100 0 0,0 0,100 A15,15 0 0,0 30,100 A70,70 0 0,1 100,30 A15,15 0 0,0 100,0" />
    </clipPath>
    <g fill="currentColor" clipPath="url(#spinner-arc)">
      <circle cx="100" cy="100" r="100" />
    </g>
  </svg>
);
