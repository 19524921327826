import type { AllHTMLAttributes, FC } from 'react';

export const Refresh: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 20 18" fill="none" {...props}>
    <path
      d="M16.3742 2.66057C14.9805 1.25609 13.1628 0.350039 11.2023 0.0826015C9.24181 -0.184836 7.24783 0.201242 5.52885 1.18111C3.80988 2.16097 2.46171 3.68 1.69294 5.50319C0.924166 7.32638 0.777638 9.3521 1.27602 11.2669C1.77441 13.1818 2.88993 14.879 4.44999 16.0961C6.01006 17.3131 7.92772 17.9822 9.90628 17.9996C11.8848 18.0171 13.814 17.3821 15.3954 16.1928C16.9767 15.0036 18.1221 13.3263 18.6542 11.4206H16.7942C16.306 12.8279 15.3899 14.0473 14.1741 14.9081C12.9584 15.7688 11.5038 16.2277 10.0142 16.2206C8.83146 16.2176 7.66762 15.9234 6.6256 15.3638C5.58357 14.8043 4.69544 13.9966 4.03972 13.0123C3.38399 12.0279 2.98087 10.8971 2.86598 9.71997C2.75108 8.5428 2.92797 7.35545 3.38098 6.26288C3.834 5.17031 4.5492 4.20616 5.46337 3.45567C6.37754 2.70518 7.46251 2.19146 8.62239 1.95991C9.78228 1.72836 10.9813 1.78612 12.1136 2.12808C13.2458 2.47005 14.2764 3.08568 15.1142 3.92057L12.4142 7.22056H19.0142V0.02057L16.3742 2.66057Z"
      fill="currentColor"
    />
  </svg>
);
