import type { AllHTMLAttributes, FC } from 'react';

export const ArrowRight: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" width="48" height="48" viewBox="0 0 48 48" {...props}>
    <polygon
      fill="currentColor"
      points="24.5 1.5 19 7.4 31.6 20.1 1 20.1 1 27.9 31.6 27.9 19 40.6 24.5 46.5 47 24 24.5 1.5"
    />
  </svg>
);
