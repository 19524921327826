import type { AllHTMLAttributes, FC } from 'react';

export const Close: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M56.8174 20.1019L52.3964 15.6809L36.186 32.186L19.9757 15.6809L15.5547 20.1019L32.0598 36.3123L15.5547 52.5226L19.9757 56.9436L36.186 40.4385L52.3964 56.9436L56.8174 52.5226L40.3123 36.3123L56.8174 20.1019Z"
      fill="currentColor"
    />
  </svg>
);
