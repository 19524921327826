import type { AllHTMLAttributes, FC } from 'react';

export const Language: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.25 17.55L13.5769 19.481C13.5256 19.627 13.4323 19.75 13.2969 19.85C13.1615 19.95 13.0125 20 12.85 20C12.5667 20 12.3458 19.8875 12.1875 19.6625C12.0292 19.4375 12.0033 19.1918 12.11 18.9255L15.15 10.8947C15.2167 10.7482 15.3125 10.6292 15.4375 10.5375C15.5625 10.4458 15.7065 10.4 15.8695 10.4H16.4806C16.6435 10.4 16.7883 10.4467 16.915 10.54C17.0417 10.6333 17.1367 10.7533 17.2 10.9L20.24 18.9696C20.3467 19.2232 20.325 19.4583 20.175 19.675C20.025 19.8917 19.8187 20 19.5561 20C19.3854 20 19.2333 19.9542 19.1 19.8625C18.9667 19.7708 18.875 19.6417 18.825 19.475L18.1599 17.55H14.25ZM14.75 16.15H17.6L16.2 12.2H16.15L14.75 16.15ZM9.4 13.6L5.975 17.025C5.80833 17.1917 5.61667 17.275 5.4 17.275C5.18333 17.275 4.99167 17.1917 4.825 17.025C4.65833 16.8583 4.575 16.6667 4.575 16.45C4.575 16.2333 4.65833 16.0417 4.825 15.875L8.3 12.4C7.8 11.8432 7.3625 11.2665 6.9875 10.6699C6.6125 10.0733 6.28333 9.45 6 8.8H7.65C7.9 9.28333 8.15833 9.7125 8.425 10.0875C8.69167 10.4625 9.01667 10.8667 9.4 11.3C10 10.65 10.4958 9.99167 10.8875 9.325C11.2792 8.65833 11.6 7.95 11.85 7.2H3.7973C3.5714 7.2 3.38204 7.12333 3.22923 6.97C3.07641 6.81667 3 6.62667 3 6.4C3 6.17217 3.07667 5.98119 3.23 5.82708C3.38333 5.67294 3.57333 5.59588 3.8 5.59588H8.6V4.79175C8.6 4.56392 8.67667 4.375 8.83 4.225C8.98333 4.075 9.17333 4 9.4 4C9.62705 4 9.81737 4.07667 9.97095 4.23C10.1246 4.38333 10.2014 4.57333 10.2014 4.8V5.6H15.0094C15.2365 5.6 15.425 5.67667 15.575 5.83C15.725 5.98333 15.8 6.17333 15.8 6.4C15.8 6.62667 15.7233 6.81667 15.57 6.97C15.4167 7.12333 15.2267 7.2 15 7.2H13.4526C13.1675 8.13333 12.7874 9.04551 12.3122 9.93652C11.837 10.8275 11.2496 11.6654 10.55 12.45L12.45 14.4L11.85 16.05L9.4 13.6Z"
      fill="currentColor"
    />
  </svg>
);
