import type { AllHTMLAttributes, FC } from 'react';

export const Spa: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <path
      fill="currentColor"
      d="M21.47,22.05c1.38-1.39,1.37-3.62,0-5-1.39-1.37-3.62-1.36-5,.03-1.37,1.36-1.36,3.6,.02,4.99,1.38,1.36,3.62,1.35,4.99-.02"
    />
    <polygon
      fill="currentColor"
      points="35.24 56 37.9 55.46 39.63 46.44 35.52 42.4 35.24 56"
    />
    <path
      fill="currentColor"
      d="M41.25,37.82s-2.09-2.97-4.18-3.3c-2.08-.32-9.81,1.5-9.81,1.5,0,0-5.25-10.24-6.55-11.15-1.3-.92-3.28-.27-4.03,.08-1.28,.65-1.9,2.2-1.9,2.2-1.19,3.88-3.16,14.46-3.16,14.46l2.22,.39,3.58-8.85,5.03,10.77,13.73-4.3,11.72,14.88,2.55-.7-9.19-15.97Z"
    />
    <path
      fill="currentColor"
      d="M34.13,21.78c.17,.48-.06,1-.54,1.18-.48,.17-1-.06-1.19-.54-.17-.47,.06-1,.54-1.17,.48-.2,1.01,.05,1.19,.53"
    />
    <path
      fill="currentColor"
      d="M45.94,24.69c.18,.48-.07,1-.54,1.19-.47,.18-1-.07-1.17-.53-.19-.49,.06-1.01,.53-1.18,.48-.19,1.02,.06,1.19,.53"
    />
    <path
      fill="currentColor"
      d="M55.18,26.85c.18,.46-.06,1-.54,1.19-.48,.17-1-.07-1.17-.55-.19-.47,.05-1,.53-1.2,.48-.17,1.01,.09,1.19,.56"
    />
    <path
      fill="currentColor"
      d="M51.09,34.17c.19,.47-.07,1.01-.54,1.19-.46,.17-1-.08-1.18-.54-.19-.48,.06-1,.54-1.19,.48-.19,1,.06,1.18,.53"
    />
    <path
      fill="currentColor"
      d="M43.35,14.09c.18,.47-.06,1-.54,1.18-.47,.19-1-.06-1.18-.53-.18-.47,.05-1,.54-1.19,.47-.19,1.01,.05,1.18,.54"
    />
    <path
      fill="currentColor"
      d="M46.61,19.01c.18,.46-.05,1-.52,1.17-.49,.18-1.02-.07-1.2-.54-.17-.46,.06-1,.54-1.19,.48-.17,1.01,.07,1.19,.55"
    />
    <path
      fill="currentColor"
      d="M36.08,15.75c.17,.49-.07,1.01-.54,1.21-.48,.17-1.01-.06-1.18-.54-.19-.49,.06-1.02,.53-1.19,.47-.19,1.01,.05,1.19,.52"
    />
    <path
      fill="currentColor"
      d="M39.86,20.14c.12,.32-.04,.68-.36,.8-.32,.11-.68-.05-.79-.36-.12-.33,.04-.68,.36-.8,.32-.12,.67,.05,.8,.36"
    />
    <path
      fill="currentColor"
      d="M30.84,13.88c.11,.33-.05,.68-.36,.78-.32,.14-.68-.04-.8-.36-.11-.31,.04-.66,.36-.78,.31-.13,.66,.03,.8,.35"
    />
    <path
      fill="currentColor"
      d="M29.97,18.6c.12,.31-.04,.67-.36,.79-.32,.11-.68-.04-.8-.37-.12-.33,.05-.67,.36-.8,.33-.11,.68,.05,.8,.37"
    />
    <path
      fill="currentColor"
      d="M33.6,27.76c.12,.32-.04,.69-.36,.8-.32,.12-.68-.04-.8-.36-.12-.32,.05-.68,.36-.8,.32-.12,.67,.03,.79,.36"
    />
    <path
      fill="currentColor"
      d="M43.53,8.41c.12,.3-.05,.66-.36,.78-.32,.11-.68-.03-.8-.36-.12-.32,.03-.66,.36-.8,.32-.11,.67,.04,.8,.37"
    />
    <path
      fill="currentColor"
      d="M39.35,25.78c.12,.31-.04,.69-.37,.79-.32,.12-.66-.05-.79-.37-.13-.3,.04-.65,.35-.77,.33-.13,.68,.03,.8,.36"
    />
    <path
      fill="currentColor"
      d="M49.21,12.77c.12,.3-.05,.68-.37,.79-.31,.12-.67-.05-.78-.37-.13-.32,.04-.66,.35-.79,.33-.12,.67,.04,.8,.37"
    />
    <path
      fill="currentColor"
      d="M55.51,33.22c.11,.32-.05,.66-.37,.78-.31,.13-.67-.04-.79-.35-.13-.32,.04-.68,.36-.8,.32-.12,.68,.05,.8,.37"
    />
    <path
      fill="currentColor"
      d="M51.71,22.69c.12,.34-.04,.69-.36,.82-.32,.11-.67-.06-.8-.37-.12-.31,.04-.67,.36-.81,.32-.09,.67,.07,.8,.37"
    />
    <path
      fill="currentColor"
      d="M45.38,31.06c.13,.32-.04,.67-.36,.79-.31,.11-.66-.04-.8-.36-.12-.31,.04-.66,.36-.79,.32-.11,.68,.05,.8,.36"
    />
    <path
      fill="currentColor"
      d="M50.47,28.27c.12,.31-.04,.66-.37,.78-.31,.12-.67-.04-.79-.34-.11-.32,.04-.68,.36-.79,.32-.13,.68,.03,.8,.36"
    />
    <path
      fill="currentColor"
      d="M52.47,18.21c.12,.31-.04,.67-.36,.79-.31,.11-.67-.05-.79-.36-.12-.32,.04-.67,.35-.8,.33-.13,.69,.04,.8,.37"
    />
    <path
      fill="currentColor"
      d="M26.89,47.6H9.38c-.51,0-.92-.41-.92-.91v-.22c0-.51,.41-.94,.92-.94H26.89c.51,0,.93,.43,.93,.94v.22c0,.5-.41,.91-.93,.91"
    />
  </svg>
);
