import type { AllHTMLAttributes, FC } from 'react';

export const Lockers: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 133 182" {...props}>
    <path
      d="M1 61v120h131V61zm122.6 111.7H9.4V69.3h114.2z"
      fill="currentColor"
    />
    <path
      d="M28 157.8h9.1V98.1H28a8.1 8.1 0 00-8 8.1v43.6a8 8 0 008 8zm76.7-59.7h-9v59.7h9a8 8 0 008.1-8v-43.6a8.1 8.1 0 00-8.1-8.1zm-18.6 0V86.6a3.5 3.5 0 00-3.5-3.5H50.2a3.5 3.5 0 00-3.5 3.5v11.5h-4v59.7h47.4V98.1zm-7 0H53.6v-6.2a1.8 1.8 0 011.8-1.8h22a1.8 1.8 0 011.7 1.8zm28.3-84.5H58.7A22.5 22.5 0 1038.5 46a22.8 22.8 0 0018.9-10.2H66l8.9-8.3 6.5 5.9 6.5-5.9 6.5 5.9 6.5-5.9 6.5 5.9 10.6-9.9zM27.7 29.1a5.5 5.5 0 110-10.9 5.5 5.5 0 010 10.9z"
      fill="currentColor"
    />
  </svg>
);
