import type { AllHTMLAttributes, FC } from 'react';

export const ChevronRight: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <polygon
      fill="currentColor"
      points="7 5.8 13.1 12 7 18.2 8.4 19.6 15.9 12 8.4 4.4 7 5.8"
    />
  </svg>
);
