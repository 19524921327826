import type { AllHTMLAttributes, FC } from 'react';

export const VideoCall: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 104 104" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52 0C59.1935 0 65.9465 1.20797 72.2605 3.62391C78.5745 6.03984 84.0839 9.31862 88.7903 13.4602C93.4967 17.6018 97.2082 22.4721 99.9249 28.0709C102.642 33.6697 104 39.6137 104 45.9028C104 52.2686 102.642 58.2509 99.9249 63.85C97.2077 69.4486 93.4967 74.3183 88.7903 78.4605C84.0839 82.6026 78.574 85.8814 72.2605 88.2968C65.947 90.7131 59.1935 91.9207 52 91.9207C46.7962 91.9207 41.6689 91.2304 36.6181 89.8493L13.0861 103.655C12.6269 103.885 12.1678 104 11.7086 104C10.9433 104 10.3311 103.77 9.87197 103.31C8.87712 102.312 8.6858 101.238 9.29801 100.088L19.2848 81.6808C13.1626 77.3089 8.41796 71.9977 5.05077 65.7469C1.68359 59.4967 0 52.8816 0 45.9021C0 39.613 1.35835 33.669 4.07506 28.0701C6.79176 22.4713 10.5033 17.6011 15.2097 13.4597C19.9161 9.31824 25.426 6.03928 31.7395 3.62334C38.053 1.20797 44.8065 0 52 0ZM77.0714 59.6049V29.4247L60.7873 36.7995V36.913V29.992C60.7873 29.1599 60.4751 28.4414 59.85 27.8362C59.2252 27.2311 58.4832 26.9286 57.6241 26.9286H29.9746C29.1155 26.9286 28.393 27.2311 27.8072 27.8362C27.2215 28.4414 26.9286 29.1599 26.9286 29.992V59.1509C26.9286 59.9831 27.2215 60.7017 27.8072 61.3066C28.393 61.9123 29.1155 62.2143 29.9746 62.2143H57.6235C58.4826 62.2143 59.2246 61.9117 59.8495 61.3066C60.4751 60.7017 60.7869 59.9831 60.7869 59.1509V52.117L77.0714 59.6049Z"
      fill="currentColor"
    />
  </svg>
);
