import type { AllHTMLAttributes, FC } from 'react';

export const TransportTicket: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    aria-hidden="true"
    width="187"
    height="182"
    viewBox="0 0 187 182"
    {...props}
  >
    <path
      d="M159.91,163.26s.51,12-16.88,12H44.93a6.56,6.56,0,0,1-.91-13H82.7V156.4H23.47a6.46,6.46,0,0,1,0-12.91l36.61,0v-5.87H13.29A6.34,6.34,0,0,1,7,131.16a6.42,6.42,0,0,1,6.27-6.56H60v-5.78H23.35a6.55,6.55,0,0,1,.73-13h8.81V100H24.08A12.27,12.27,0,0,0,11.82,112.3a17.72,17.72,0,0,0,1.45,6.51A12.33,12.33,0,0,0,13,143.47a12.19,12.19,0,0,0-1.82,6.41,12.37,12.37,0,0,0,12.27,12.39h11a12.14,12.14,0,0,0-1.84,6.44A12.35,12.35,0,0,0,44.93,181h99.82c17.06-.16,20.09-12.05,20.09-12.05H186v-5.69Z"
      fill="currentColor"
    />
    <path
      d="M165,107.74s-1.65-15.8-16.06-19.54l-27-7-3.49-.92V37.3H63.53V147.66h54.83V101.73l-3.42-1L81.17,92.08A7.55,7.55,0,1,1,85,77.46l62.85,16.39c12.8,4.74,11.57,19.68,11.57,19.68H186v-5.78Zm-53.19-48H100.07V56.8a32.17,32.17,0,0,0,6-6.17,6.18,6.18,0,0,0,1.49-3.36,2.5,2.5,0,0,0-2.73-2.39,5.94,5.94,0,0,0-2.43.56,9.41,9.41,0,0,0-1.69.91l-.24-3.08a12.47,12.47,0,0,1,5.21-1.21c3.35,0,5.57,1.87,5.57,4.72a8.91,8.91,0,0,1-2,5.17,29.54,29.54,0,0,1-4.66,5h7.17Z"
      fill="currentColor"
    />
    <path
      d="M36.39,1V111.37H60V33.8H91.22V1ZM83.51,25.27H80s-.05,0-.05-13.59l-2.88,2.51-1.81-2.28,5.19-4.06h3.05Z"
      fill="currentColor"
    />
  </svg>
);
