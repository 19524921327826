import type { AllHTMLAttributes, FC } from 'react';

export const StairsUp: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" width="48" height="48" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor">
      <polygon points="47 9 38 9 38 21 27 21 27 32 15 32 15 44 1 44 1 39 10 39 10 27 22 27 22 16 33 16 33 4 47 4 47 9" />
      <path d="M8.1,12.9l-.8-.8L12.4,7l.8-.8H6L8.3,4H17v8.7L14.7,15V8.8a4.5,4.5,0,0,1,.1-1l-.8.8L8.9,13.7Z" />
    </g>
  </svg>
);
