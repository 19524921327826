import type { AllHTMLAttributes, FC } from 'react';

export const CashChange: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      fill="currentColor"
      d="M40.78,10.83v-2.83h-4.93V43.13h4.93v-2.34l7.08,2.26v-2.3l2.69,.86v-2.92l2.53,.81V14.75l-12.29-3.92Zm5.68,28.08v2.3l-5.68-1.81-3.3-1.05V16.39l3.3,1.05,5.68,1.81v19.65Zm2.69-2.06v2.92l-1.29-.41V18.3l-10.38-3.31v-1.27l11.67,3.72v19.4Zm2.53,.81l-1.13-.36V16.49l-13.06-4.17v-1.15l14.2,4.53v21.95Z"
    />
    <path
      fill="currentColor"
      d="M39.16,30.91c.21,.1,.39,.23,.53,.37,.14,.15,.24,.3,.32,.47,.08,.17,.14,.35,.16,.54,.03,.19,.04,.38,.04,.57l.54,.17v-1.46l4.03,1.29v-.68l-5.63-1.79v.52Z"
    />
    <path
      fill="currentColor"
      d="M44.41,28.13c-.14-.18-.31-.34-.49-.48-.19-.14-.39-.26-.6-.37s-.43-.21-.65-.29c-.22-.08-.43-.15-.64-.22-.21-.07-.42-.13-.64-.19-.22-.06-.44-.1-.65-.13-.21-.03-.41-.03-.6,0-.19,.02-.36,.07-.5,.16-.15,.09-.26,.23-.34,.41-.09,.18-.13,.42-.13,.71s.04,.56,.13,.79c.09,.24,.2,.45,.34,.63,.14,.18,.31,.34,.5,.48,.19,.14,.39,.27,.6,.38,.21,.11,.43,.21,.65,.28,.22,.08,.44,.16,.64,.22,.21,.07,.42,.13,.64,.19,.22,.06,.44,.1,.65,.13,.21,.03,.41,.03,.6,0,.19-.02,.35-.07,.49-.17,.15-.09,.26-.23,.34-.41,.08-.18,.12-.42,.12-.71s-.04-.56-.12-.79c-.08-.23-.19-.44-.34-.62m-.36,1.82c-.16,.11-.35,.16-.58,.16-.23,0-.48-.04-.74-.11-.26-.07-.5-.14-.71-.21-.14-.04-.29-.09-.46-.15-.17-.06-.34-.12-.5-.2-.17-.08-.33-.16-.49-.26-.16-.1-.3-.21-.41-.33-.12-.13-.22-.27-.29-.42-.07-.16-.11-.33-.11-.52s.04-.34,.11-.45c.07-.11,.17-.19,.29-.24,.12-.05,.26-.07,.41-.07,.16,0,.32,.02,.49,.05,.16,.03,.33,.07,.5,.12,.16,.05,.32,.1,.46,.14,.21,.07,.45,.15,.71,.25,.26,.1,.51,.22,.74,.36,.23,.14,.42,.32,.58,.53,.16,.21,.24,.46,.24,.74s-.08,.48-.24,.59"
    />
    <path
      fill="currentColor"
      d="M40.13,22.02c-.19,.02-.36,.08-.5,.17-.14,.09-.26,.23-.34,.41-.08,.18-.13,.42-.13,.71s.04,.55,.13,.79c.09,.23,.2,.45,.34,.63,.14,.18,.31,.34,.5,.48,.19,.14,.39,.27,.6,.37,.21,.11,.43,.21,.65,.29,.22,.08,.44,.16,.65,.22,.21,.07,.42,.13,.64,.19,.22,.06,.44,.1,.65,.13,.21,.03,.41,.03,.6,0,.19-.02,.35-.08,.5-.17,.14-.09,.26-.23,.34-.41,.08-.18,.12-.42,.12-.71s-.04-.56-.12-.79c-.08-.23-.19-.44-.34-.62-.14-.18-.31-.34-.5-.48s-.39-.26-.6-.37c-.21-.11-.43-.21-.65-.28-.22-.08-.44-.16-.64-.22-.21-.07-.42-.13-.65-.19-.22-.06-.44-.1-.65-.13-.21-.02-.41-.03-.6,0m1.43,.9c.17,.05,.32,.1,.46,.14,.21,.07,.45,.15,.71,.25,.26,.1,.51,.22,.74,.36s.42,.32,.58,.53c.16,.21,.24,.46,.24,.74s-.08,.48-.24,.59c-.16,.11-.35,.16-.58,.16-.23,0-.47-.04-.74-.11-.26-.07-.5-.14-.71-.21-.14-.04-.29-.09-.46-.15-.17-.06-.33-.12-.5-.2-.17-.08-.33-.16-.49-.26-.16-.09-.3-.21-.42-.33-.12-.12-.22-.27-.29-.42-.07-.15-.11-.33-.11-.52s.04-.34,.11-.45c.08-.11,.17-.19,.29-.24,.12-.05,.26-.07,.42-.07,.16,0,.32,.03,.49,.05,.17,.03,.33,.07,.5,.12"
    />
    <path
      fill="currentColor"
      d="M27.3,41.42c-1.28-.79-2.9-.12-2.9-.12l-1.17,6.37-.84-.08,1.76-10.18s.07-1.65-1.51-1.92c-1.66-.29-2.2,1.41-2.2,1.41,0,0-1.16,7.07-2.21,8.5-1.01,1.38-1.7,2.26-1.97,4.97-.27,2.71,.68,5.62,.68,5.62h9.17l2.28-11.56s.2-2.23-1.08-3.02"
    />
    <path
      fill="currentColor"
      d="M14.6,44.47h3.21l.69-2.65-3.81-.02s-1.01,.14-1.15,1.2c-.14,1.06,1.05,1.47,1.05,1.47"
    />
    <path
      fill="currentColor"
      d="M14,47.37c-.42,1.36,1.46,1.86,1.46,1.86,0,0,.12-.81,.36-1.54,.23-.74,.66-1.47,.66-1.47,0,0-2-.4-2.48,1.15"
    />
    <path
      fill="currentColor"
      d="M18.86,39.15l.22-1.15h-.87l.41-13.94h-2.89l-.7,13.94h-.65c-.83,0-1.5-.67-1.5-1.5l.75-12.43h-1.15l-.75,12.43c0,1.46,1.19,2.65,2.65,2.65h4.47Z"
    />
    <path
      fill="currentColor"
      d="M27.25,24.06l.99,12.43c0,.83-.67,1.5-1.5,1.5h-1.62l-.21,1.15h1.82c1.46,0,2.65-1.19,2.65-2.65l-.99-12.43h-1.15Z"
    />
    <rect fill="currentColor" x="10.92" y="21.42" width="19.13" height="1.48" />
  </svg>
);
