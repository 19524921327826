import type { AllHTMLAttributes, FC } from 'react';

export const SpecialAssistance: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 146 182" {...props}>
    <path
      d="M33 92.7l.5-.4-.9-15.3-1 .4A54.6 54.6 0 0055.2 181a53.9 53.9 0 0049.8-32.9l-9.2-19.7A40.3 40.3 0 1133 92.7z"
      fillRule="evenodd"
      fill="currentColor"
    />
    <path
      d="M119.4 158l25.6-11.9-6.1-13-12.6 5.9-16.5-35.2c-1.2-3.1-5.6-7.9-11.5-8.1H58l-.7-12h39.5V72.2H56.6l-1.2-19.3C54.9 37.1 50.1 38.3 46 38h-7l4 72h50c3 0 4.2 1.2 5.6 3.5z"
      fillRule="evenodd"
      fill="currentColor"
    />
    <circle cx="46" cy="16" r="15" fill="currentColor" />
  </svg>
);
