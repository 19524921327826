import type { AllHTMLAttributes, FC } from 'react';

export const Volume: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg width="111" height="89" viewBox="0 0 111 89" fill="none" {...props}>
    <path
      d="M50.663 0.331475C49.1341 -0.373918 47.3706 0.0960706 46.1945 1.27227L25.4995 22.0845H4.23556C1.88404 22.0845 0.00244141 23.966 0.00244141 26.2004V62.0643C0.00244141 64.4158 1.88401 66.1802 4.23556 66.1802H25.4995L46.3125 87.1098C47.136 87.9333 48.1941 88.4033 49.2522 88.4033C49.8403 88.4033 50.193 88.286 50.663 88.0506C52.1918 87.3452 53.1327 85.8162 53.1327 84.1702L53.1319 4.21141C53.1319 2.44713 52.3092 0.919407 50.663 0.331475ZM69.5944 62.0644C68.7709 63.0052 67.5956 63.4752 66.4194 63.4752C65.4786 63.4752 64.4205 63.1225 63.5971 62.4171C61.8336 60.8882 61.7155 58.184 63.2444 56.4204C73.7096 44.6617 64.3025 33.2558 63.2444 31.9624C61.7155 30.1989 61.8336 27.6119 63.5971 26.0831C65.3606 24.5542 67.9476 24.6723 69.4764 26.4358C75.1212 32.5496 82.2939 47.954 69.5944 62.0644ZM82.4112 73.2349C81.5877 74.1757 80.4123 74.6457 79.2361 74.6457C78.2953 74.6457 77.2372 74.293 76.4138 73.5876C74.6504 72.0587 74.5322 69.3545 76.0611 67.5909C96.8742 44.4263 76.8846 21.6146 76.062 20.6739C74.5331 18.9104 74.6512 16.3234 76.4146 14.6772C78.1781 13.1483 80.7651 13.2664 82.4113 15.0299C82.6467 15.3826 108.045 44.5438 82.4112 73.2349ZM95.6986 81.7011C94.8751 82.6419 93.6998 83.1119 92.5236 83.1119C91.5828 83.1119 90.5247 82.7592 89.7013 82.0538C87.9379 80.5249 87.8197 77.8207 89.3486 76.0571C101.225 62.8868 104.87 48.1891 100.049 32.4325C96.5214 20.4385 89.3486 12.443 89.3486 12.3248C87.8197 10.5614 87.9378 7.97432 89.7013 6.32811C91.4648 4.79924 94.0518 4.91733 95.698 6.68081C95.9342 7.03351 128.857 44.6614 95.6986 81.7011Z"
      fill="currentColor"
    />
  </svg>
);
