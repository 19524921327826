import type { Attributes, FC } from 'react';
import { createElement } from 'react';
import type { IconLibrary, IconName } from '../types/icon';

export interface IconProviderProps extends Attributes {
  name: IconName;
}

export const createIconProvider = (icons: IconLibrary): FC<IconProviderProps> =>
  function IconProvider({ name, ...props }: IconProviderProps) {
    if (!icons[name]) {
      return null;
    }
    return createElement(icons[name], props);
  };
