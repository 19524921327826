import type { AllHTMLAttributes } from 'react';

export const PrmThumbsUp = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    fill="none"
    viewBox="0 0 56 56"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m51.019 27.939-3.3588 15.934c-0.3458 1.6406-1.7583 2.7857-3.4343 2.7857h-23.998c-1.1098 0-2.0269-0.4371-2.7263-1.2988-0.0612-0.0755-0.1297-0.14-0.2029-0.1972v-23.672c4.6989-0.5115 10.385-3.8955 12.134-12.069 0.0177-0.08229 0.0526-0.17943 0.24-0.28686 0.5532-0.316 1.7217-0.31943 2.6595-0.00743 0.8451 0.28115 1.5314 1.0532 2.0405 2.296 1.3195 3.2223 0.9492 8.6195-0.2365 10.94-0.204 0.3989-0.1829 0.8772 0.0554 1.256 0.2383 0.38 0.6577 0.6012 1.1068 0.596 1.5806-0.0354 3.2509-0.1623 4.8658-0.2845 4.232-0.3206 9.028-0.6835 10.488 0.9828 0.5451 0.6246 0.6651 1.6137 0.3668 3.0263zm-46.197 18.728h9.9303v-25.667h-9.9303v25.667z"
      fill="currentColor"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
);
