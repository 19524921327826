import type { AllHTMLAttributes, FC } from 'react';
import React from 'react';

export const ThumbsUp: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    aria-hidden="true"
    focusable="false"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      d="M64 29.6782c0-3.7686-3.0316-6.797-6.8042-6.797H43.0484c1.3474-2.8938 2.8969-7.3355 2.8969-12.1136 0-4.509-2.4253-8.0757-6.8716-10.162a6.1485 6.1485 0 00-5.3221 0c-1.6842.8076-2.8969 2.2882-3.3684 4.1052l-.8758 3.2976c-1.5495 5.9222-4.7832 11.1041-9.1621 14.6036-.8085.673-1.6842 1.2113-2.56 1.7497-.741-.8748-1.819-1.4132-3.0316-1.4132H3.9747C1.819 22.9485 0 24.6982 0 26.919v31.9664c0 2.1535 1.7516 3.9705 3.9747 3.9705h10.7116c1.3474 0 2.4926-.673 3.2337-1.6824C24.4547 63.0578 30.4505 64 36.9853 64h15.6294c3.7727 0 6.8042-3.0284 6.8042-6.797 0-1.2114-.3368-2.4228-.8757-3.365 1.8863-1.2113 3.1663-3.3648 3.1663-5.7202 0-1.2114-.3369-2.3554-.8758-3.365C62.72 43.5416 64 41.388 64 39.0327c0-1.7497-.6737-3.3649-1.7516-4.5762C63.3264 33.043 64 31.428 64 29.6782zm-10.687 7.3474h4.323c1.2833 0 2.364 1.0748 2.364 2.3512 0 1.2763-1.0132 2.3511-2.364 2.3511h-5.1335c-1.2158 0-2.229 1.0077-2.229 2.2169 0 1.2091 1.0132 2.2168 2.229 2.2168h2.8369c1.2833 0 2.364 1.0748 2.364 2.3512 0 1.2763-1.0131 2.3512-2.364 2.3512h-4.5256c-1.2158 0-2.229 1.0076-2.229 2.2168s1.0132 2.2168 2.229 2.2168h2.2966c1.2833 0 2.364 1.0749 2.364 2.3512 0 1.2764-1.0131 2.3512-2.364 2.3512h-15.738C31.0905 60 25.3492 59.0595 19 57.1786v-27.744c1.486-.739 2.972-1.6794 4.3904-2.8214 5.1335-4.0978 8.9836-10.0765 10.7397-16.9957l.8781-3.2917c.2027-.6717.6755-1.0076.9457-1.142.2702-.1343.878-.3359 1.486 0 2.9044 1.3435 4.3229 3.2917 4.3229 6.046 0 6.852-3.9852 13.1665-3.9852 13.1665-.4053.6718-.4728 1.5451-.0676 2.284.4053.739 1.1483 1.142 1.9589 1.142h17.967c1.2834 0 2.3641 1.0749 2.3641 2.3512 0 1.2764-1.0132 2.3512-2.364 2.3512h-4.323c-1.2158 0-2.229 1.0077-2.229 2.2169 0 1.2091.9457 2.284 2.229 2.284z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
