import type { AllHTMLAttributes, FC } from 'react';

export const TaxRefund: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132 182" {...props}>
    <path
      d="M1,1V68H131V1ZM66,65.4A30.9,30.9,0,1,1,97.1,34.5,30.9,30.9,0,0,1,66,65.4Z"
      fill="currentColor"
    />
    <path
      d="M66,6.8A27.7,27.7,0,1,0,93.8,34.5,27.7,27.7,0,0,0,66,6.8Zm0,53A25.3,25.3,0,1,1,91.2,34.4,25.4,25.4,0,0,1,66,59.8Z"
      fill="currentColor"
    />
    <polygon
      points="74 26.3 87 26.3 87 29.1 82.3 29.1 82.3 42.3 78.7 42.3 78.7 29.1 74 29.1 74 26.3"
      fill="currentColor"
    />
    <polygon
      points="48.8 26.3 53 38.4 57 26.3 60.6 26.3 55 42.3 50.7 42.3 45 26.3 48.8 26.3"
      fill="currentColor"
    />
    <path
      d="M69.3,26.3H65.4l-6.6,16h3.8l1.5-3.6h6.4l1.4,3.6h4Zm-4.2,9.6L67.3,30l2.1,5.9Z"
      fill="currentColor"
    />
    <polygon
      points="99.9 181 9.1 165.1 17 120.2 21.2 120.9 14 161.6 100.6 176.8 99.9 181"
      fill="currentColor"
    />
    <path
      d="M62.8,96.8v-20h6.4v20a21.43,21.43,0,0,1-.2,3.1,12.07,12.07,0,0,1,1.9-2.2l12-12v9L66,111.6,49,94.7v-9l12.1,12a14.65,14.65,0,0,1,1.8,2.2S62.8,98.7,62.8,96.8Z"
      fill="currentColor"
    />
    <path
      d="M115.8,112.7a15.1,15.1,0,1,0,15.1,15.1A15.1,15.1,0,0,0,115.8,112.7Zm-.9,23.8a17.32,17.32,0,0,1-4.7-.6v-3.8a9,9,0,0,0,4.2,1c1.7,0,3.6-.6,3.6-2.8s-2.1-2.6-3.9-2.6a15.68,15.68,0,0,0-3.8.4v-9.4h11.4v3.2h-7.4v2.9h1.4c3.5,0,6.7,1.5,6.7,5.4S118.8,136.5,114.9,136.5Z"
      fill="currentColor"
    />
    <path
      d="M93.9,105.1a10.2,10.2,0,0,0-2.71,20.22c.3,0,.61.07.91.08a9.89,9.89,0,0,0,5.7-1.1,16.9,16.9,0,0,1,5.3-10.4A10.2,10.2,0,0,0,93.9,105.1Zm-1.4,16v-8.6l-2.3,1.6L88.9,112l3.9-2.6h2.8v11.7Z"
      fill="currentColor"
    />
    <path
      d="M88.3,137.5c-3.6-.6-4.6,5.3-5.2,8.5s-1.6,8.9,2,9.6,4.7-5.3,5.2-8.3S91.9,138.2,88.3,137.5Z"
      fill="currentColor"
    />
    <path
      d="M65.6,133.5c-3.6-.6-4.6,5.3-5.2,8.5s-1.6,8.9,2,9.6,4.7-5.2,5.3-8.3S69.3,134.2,65.6,133.5Z"
      fill="currentColor"
    />
    <path
      d="M102.3,141.3c-3.4-3.4-4.8-8-5.1-12.5a14.91,14.91,0,0,1-4.2.6,14.29,14.29,0,0,1-11.5-5.8l-53.1-9.2L21,156.5l88,15.4,4.4-25.4A18.81,18.81,0,0,1,102.3,141.3Zm-55,10.9-4.9-.8,3.3-18.9L41,135.1l-1.9-3.6,8.1-4.5,4.4.8Zm25.3-8.1c-1,5.7-3.7,12.4-10.8,11.1s-7.2-8.9-6.3-14.1,4.1-12.4,10.7-11.2,7.4,8.5,6.4,14.2Zm22.6,4c-1,5.7-3.7,12.4-10.7,11.1s-7.2-9-6.3-14.1,4.1-12.4,10.7-11.2,7.3,8.5,6.3,14.2Z"
      fill="currentColor"
    />
  </svg>
);
