import type { AllHTMLAttributes, FC } from 'react';

export const Scanner: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    aria-hidden="true"
    width="339"
    height="237"
    viewBox="0 0 339 237"
    {...props}
  >
    <path
      fill="currentColor"
      d="M0,0 L3.61111113e-07,7.22220617 L0,65 L14.4444447,65 L14.4444447,14.4444123 L65,14.4444123 L65,0 L7.22222218,0 L0,0 Z M274,0 L274,14.4444123 L324.555556,14.4444123 L324.555556,65 L339,65 L339,7.22220617 L339,0 L331.777778,0 L274,0 Z M29,29 L29,209 L51,209 L51,29 L29,29 Z M58,29 L58,209 L65,209 L65,29 L58,29 Z M76,29 L76,209 L112,209 L112,29 L76,29 Z M126,29 L126,209 L133,209 L133,29 L126,29 Z M141,29 L141,209 L148,209 L148,29 L141,29 Z M155,29 L155,209 L162,209 L162,29 L155,29 Z M169,29 L169,209 L198,209 L198,29 L169,29 Z M205,29 L205,209 L227,209 L227,29 L205,29 Z M242,29 L242,209 L249,209 L249,29 L242,29 Z M256,29 L256,209 L270,209 L270,29 L256,29 Z M281,29 L281,209 L288,209 L288,29 L281,29 Z M296,29 L296,209 L310,209 L310,29 L296,29 Z M0,172 L0,229.777778 L0,237 L7.22222222,237 L65,237 L65,222.555556 L14.4444444,222.555556 L14.4444444,172 L0,172 Z M324.555556,172 L324.555556,222.555556 L274,222.555556 L274,237 L331.777778,237 L339,237 L339,229.777778 L339,172 L324.555556,172 Z"
    />
  </svg>
);
