import type { AllHTMLAttributes, FC } from 'react';

export const Transfers: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="transfers"
    viewBox="0 0 191 182"
    {...props}
  >
    <rect
      className="cls-1"
      x="80"
      y="166"
      width="110"
      height="15"
      fill="currentColor"
    />
    <path
      id="plane_down"
      data-name="plane down"
      className="cls-2"
      fill="currentColor"
      d="M47.5,98.1c4.1,1.3,10.1,1.9,11.1-.2S53.8,89.8,50,88L39.2,83,37,57.7l-5.3-2.5L27.5,77.6,12.4,70.5l-.9-10.2L6.9,58.2,2.7,67.3C2.1,68.6.5,71,1.2,72.4c3.8,7.2,22,14.2,22.1,14.2L8.8,104.3l5.3,2.4L34.9,92.3l10.2,5v1.6a2.4,2.4,0,1,0,.2,4.7,2.2,2.2,0,0,0,2.2-2.4Z"
    />
    <path
      id="plane_up"
      data-name="plane up"
      className="cls-2"
      fill="currentColor"
      d="M71.4,1,50.6,15.4l-10.7-5c-3.7-1.7-12-2.4-13.1-.1s5,7.8,8.7,9.5l10.7,5,2.4,25.1L54,52.4,58,30c15.6,7.3,17.8,8.3,25.1,7.7,1.7-.4,2.4-3.1,3-4.4l4.3-9.1L85.8,22l-8.4,5.9-15.1-7L76.8,3.5Z"
    />
    <path
      id="arrow_down"
      data-name="arrow down"
      className="cls-2"
      fill="currentColor"
      d="M100.8,140l-3.9.2a61.6,61.6,0,0,1-38.2-11.6,59.6,59.6,0,0,1-13.6-13.4l-4.3,3.5a69.7,69.7,0,0,0,6.7,7.7,67.3,67.3,0,0,0,49.6,19.3l3.7-.2a3.7,3.7,0,0,0-2.1,1.1L88.3,157h7.9l14.6-14.7L96.1,127.7H88.3l10.4,10.4A13.2,13.2,0,0,0,100.8,140Z"
    />
    <path
      id="arrow_up"
      data-name="arrow up"
      className="cls-2"
      fill="currentColor"
      d="M106.4,12.5a10.6,10.6,0,0,0,2.4-1.2l13-6.9-7.5-2.3L96,11.9l9.7,18.3,7.5,2.2-6.9-13a3.4,3.4,0,0,0-1.7-1.6,46.8,46.8,0,0,1,8.4,2,83.6,83.6,0,0,1,8.3,3.2,61.5,61.5,0,0,1,8.3,106.4l3.5,4.4A67,67,0,0,0,122.9,17.7a50.1,50.1,0,0,0-8.4-3.2A53.3,53.3,0,0,0,106.4,12.5Z"
    />
  </svg>
);
