import type { AllHTMLAttributes, FC } from 'react';

export const PrayerRoomsMale: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      fill="currentColor"
      d="M28.36,34.26c-4.65,0-8.41,3.7-8.51,8.31h17.03c-.11-4.61-3.87-8.31-8.51-8.31"
    />
    <path
      fill="currentColor"
      d="M13.98,56h12.69v-4.79h0c.03-.91,1.69-1.64,1.69-1.64,0,0,1.66,.73,1.69,1.64v4.79h12.7v-12.14H13.98v12.14Z"
    />
    <path
      fill="currentColor"
      d="M11.13,26.25h0c0-.67-.56-1.21-1.23-1.21s-1.22,.54-1.23,1.21l-.04,1.82h2.53l-.03-1.82Z"
    />
    <polygon
      fill="currentColor"
      points="8.26 33.38 7.77 56 12.04 56 11.55 33.38 8.26 33.38"
    />
    <path
      fill="currentColor"
      d="M26.14,32.37c.44,.29,.95,.45,1.48,.45,1.46,0,2.65-1.18,2.65-2.64s-1.19-2.64-2.65-2.64c-.38,0-.77,.09-1.11,.25-.17,.07-.92,.51-.75,.67,.09,.09,.14,.03,.22,0,.24-.11,.5-.16,.76-.16,1.05,0,1.89,.84,1.89,1.88s-.85,1.89-1.89,1.89c-.21,0-.43-.05-.64-.12-.08-.03-.3-.15-.38-.06-.13,.14,.33,.41,.42,.48"
    />
    <polygon
      fill="currentColor"
      points="7.93 32.49 11.97 32.49 12.6 28.95 7.3 28.95 7.93 32.49"
    />
    <path
      fill="currentColor"
      d="M48.51,12.58c1.2,0,2.18-1.02,2.18-2.29s-.98-2.29-2.18-2.29-2.18,1.02-2.18,2.29,.98,2.29,2.18,2.29"
    />
    <path
      fill="currentColor"
      d="M53.32,15.18c-.24-.45-.56-.86-1.04-1.19-.51-.35-1.1-.58-2.03-.69,0,0-.44,.63-1.75,.63s-1.75-.61-1.75-.62c-.91,.11-1.5,.33-2,.68-.47,.33-.81,.74-1.04,1.19l-3.41,9.02,1.62,.86,3.03-5.94v4.68h7.12v-4.68l3.03,5.94,1.59-.86-3.38-9.02Z"
    />
    <polygon
      fill="currentColor"
      points="48.98 25.56 48.19 25.56 45.11 25.56 44.94 38.25 46.87 38.25 48.57 28.03 50.11 38.25 52.04 38.25 51.84 25.56 48.98 25.56"
    />
  </svg>
);
