import type { FC } from 'react';

export const SmokingAreas: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      fill="currentColor"
      d="M46.41,36.86h1.22v-2.44h-1.22c-5.25,0-9.43-4.11-9.43-9.28v-5.94h-2.51v5.94c0,6.54,5.33,11.72,11.95,11.72"
    />
    <path
      fill="currentColor"
      d="M47.63,28.49h-1.22c-1.98,0-3.5-1.52-3.5-3.35v-2.97h-2.44v2.97c0,3.2,2.59,5.78,5.94,5.78h1.22c3.27,0,5.94,2.66,5.94,5.93h2.44c0-4.64-3.73-8.37-8.37-8.37"
    />
    <rect fill="currentColor" x="51.34" y="40.21" width="1.78" height="7.1" />
    <rect fill="currentColor" x="54.22" y="40.21" width="1.78" height="7.1" />
    <rect fill="currentColor" x="20.57" y="40.21" width="28.34" height="7.1" />
    <rect fill="currentColor" x="8" y="40.21" width="10.55" height="7.1" />
    <path
      fill="currentColor"
      d="M33.6,29.91c-.1-.08-.21-.15-.31-.24-2-1.7-3.25-4.21-3.25-7.04v-5.94h-2.51v5.94c0,4.43,2.44,8.22,6.07,10.22v-2.94Z"
    />
  </svg>
);
