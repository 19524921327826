import type { AllHTMLAttributes, FC } from 'react';

export const Relaxation: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <path
      fill="currentColor"
      d="M33.84,41.79l-9.83,.85L10.39,21.45c-1.13-1.85-3.04-.89-2.18,.9l11.76,24.02h4.74l-4.43,7.78h4.24l4.43-7.78h8.21c.77-1.76-.27-5-3.33-4.58"
    />
    <path
      fill="currentColor"
      d="M21.84,16.63c1.62-1.37,1.82-3.8,.45-5.42-1.37-1.62-3.8-1.82-5.42-.45-1.62,1.38-1.82,3.8-.45,5.43,1.37,1.62,3.8,1.82,5.42,.45"
    />
    <polygon
      fill="currentColor"
      points="39.27 53.95 42.22 53.62 44.93 43.98 40.84 39.2 39.27 53.95"
    />
    <path
      fill="currentColor"
      d="M47.5,34.74s-2-3.42-4.23-3.97c-2.23-.55-10.5,1.76-10.5,1.76l-3.08-8.07,9.94,1.64,.41-2.87s-14.97-5.07-15.46-5.14l-.17-.03c-.77-.08-1.6,.04-2.42,.42-2.59,1.2-3.15,3.97-1.97,6.7l6.47,14.45,15.32-3.41,11.36,17.26,2.83-.51-8.5-18.22Z"
    />
    <path
      fill="currentColor"
      d="M16.34,28.27c1.24-.8,1.23-3.03-.02-4.98-1.25-1.95-3.27-2.89-4.52-2.09-.03,.02-.05,.04-.08,.06l4.53,7.06s.06-.03,.09-.05"
    />
  </svg>
);
