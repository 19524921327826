import type { AllHTMLAttributes, FC } from 'react';

export const Error: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="0 0 48 48" {...props}>
    <circle
      style={{ fill: 'var(--color-error, red)' }}
      cx="24"
      cy="24"
      r="19"
    />
    <rect fill="#ffffff" x="22" y="11" width="4" height="18" />
    <circle fill="#ffffff" cx="24" cy="35" r="2" />
  </svg>
);
