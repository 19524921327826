import type { AllHTMLAttributes, FC } from 'react';

export const ScannerNew: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M3 8.138V3.242h4.932v1.407H4.415v3.49H3ZM21 16.345v4.897h-4.932v-1.408h3.516v-3.489H21ZM7.914 21.242H3.017V16.31h1.408v3.516h3.489v1.416ZM16.086 3.242h4.897v4.931h-1.408V4.657h-3.49V3.242Z"
    />
    <path
      fill="currentColor"
      d="M6.017 5.707h2v5.324h-2zM6.017 13.452h2v5.324h-2zM8.611 5.707h1v5.324h-1zM8.611 13.452h1v5.324h-1zM20 11.742v1H4v-1h16ZM10.204 5.707h1v5.324h-1zM10.204 13.452h1v5.324h-1zM14.39 5.707h1v5.324h-1zM14.39 13.452h1v5.324h-1zM15.983 5.707h2v5.324h-2zM15.983 13.452h2v5.324h-2zM11.796 5.707h2v5.324h-2zM11.796 13.452h2v5.324h-2z"
    />
  </svg>
);
