import type { AllHTMLAttributes, FC } from 'react';

export const Medical: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 182 182" {...props}>
    <path
      d="M91 1a90 90 0 1090 90A90 90 0 0091 1zm0 175.1A85.1 85.1 0 11176.1 91 85.1 85.1 0 0191 176.1z"
      fillRule="evenodd"
      fill="currentColor"
    />
    <path
      fill="currentColor"
      d="M113.2 68.8h43.4v43.3h-43.4v43.3H69.9v-43.3H26.6V68.8h43.3V25.4h43.3v43.4z"
    />
  </svg>
);
