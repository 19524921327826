import type { AllHTMLAttributes, FC } from 'react';

export const Exit: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 182 182" {...props}>
    <path
      fill="currentColor"
      d="M118.69,1H1V181H181V166H16V16h87.69V27.54h15Z"
    />
    <g>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M89.61,87.29a13.63,13.63,0,0,0,1.89,2.07c.86.7,11.41,7.37,11.41,7.37l5-7a144,144,0,0,1-12.88-8.09L86.4,64.28C85,61.58,81.88,59,77,58.48,74,58.15,70,60,68,62L53.83,77a11.35,11.35,0,0,0-2.9,4.53L45.22,98.21l8.33,2.62L57.83,85a7,7,0,0,1,1.82-2.7l7.32-7L65.74,96.73l-7.85,22.14a8.34,8.34,0,0,1-1.52,2.91L35.89,143.22l8.32,9.05,20.25-22.78a24.24,24.24,0,0,0,2.61-4.79l6.26-16.51,12.59,17.13a7.06,7.06,0,0,1,1.42,5l-2.08,31.07h11L97,127.27c0-2.32-.56-3.66-2-5.9L82.85,101,84.38,78.1Z"
      />
      <path
        fill="currentColor"
        d="M81.31,54.65a9.44,9.44,0,1,0-9.45-9.44A9.44,9.44,0,0,0,81.31,54.65Z"
      />
    </g>
  </svg>
);
