import type { AllHTMLAttributes, FC } from 'react';

export const Min: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    width="52"
    height="8"
    viewBox="0 0 52 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.1501 7.04834H0.00012207V0.0483398H51.1501V7.04834Z"
      fill="currentColor"
    />
  </svg>
);
