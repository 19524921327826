import type { AllHTMLAttributes } from 'react';

export const PrmInfo = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 120 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.8304 92.0255H67.0338V44.5679H45V53.0425H52.7966V92.0255H45V100.5H74.8304V92.0255Z"
      fill="currentColor"
    />
    <path
      d="M67.3684 34.3983H52.7921V20.5H67.3684V34.3983Z"
      fill="currentColor"
    />
  </svg>
);
