import type { AllHTMLAttributes, FC } from 'react';

export const Toilet: FC = (props: AllHTMLAttributes<SVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <polygon
        fill="currentColor"
        points="41.64 53.29 43.82 53.29 43.83 45.68 40.75 45.68 41.64 53.29"
      />
      <polygon
        fill="currentColor"
        points="45.52 53.29 47.7 53.29 48.59 45.68 45.52 45.68 45.52 53.29"
      />
      <path
        fill="currentColor"
        d="M44.67,17.12c1.63,0,2.96-1.44,2.96-3.21s-1.32-3.21-2.96-3.21-2.96,1.44-2.96,3.21,1.32,3.21,2.96,3.21"
      />
      <path
        fill="currentColor"
        d="M53.89,34.55l2.11-1.19-4.6-12.58c-.32-.63-.79-1.21-1.46-1.67-.71-.49-1.55-.82-2.86-.98,0,.06-2.41,2.55-2.41,2.55,0,0-2.35-2.42-2.41-2.55-1.31,.16-2.15,.49-2.86,.98-.67,.46-1.14,1.04-1.46,1.67l-4.61,12.58,2.11,1.19,4.23-8.32,1.37,5.1-4.69,12.06h16.61l-4.68-12.06,1.37-5.1,4.23,8.32Z"
      />
      <path
        fill="currentColor"
        d="M19.42,17.31c1.68,0,3.05-1.44,3.05-3.21s-1.36-3.21-3.05-3.21-3.04,1.43-3.04,3.21,1.36,3.21,3.04,3.21"
      />
      <path
        fill="currentColor"
        d="M24.69,19.29c-.7-.48-1.52-.8-2.8-.97,0,.03-.61,.89-2.44,.89s-2.44-.88-2.44-.89c-1.3,.16-2.13,.48-2.84,.97-.66,.46-1.13,1.04-1.45,1.67l-4.71,12.64,2.21,1.19,4.23-8.32v6.56h9.96v-6.56l4.23,8.32,2.26-1.19-4.76-12.64c-.32-.63-.79-1.2-1.45-1.67"
      />
      <polygon
        fill="currentColor"
        points="19.87 35.51 18.78 35.51 14.78 35.51 14.49 53.3 17.19 53.3 19.35 38.95 21.72 53.3 24.41 53.3 24.18 35.51 19.87 35.51"
      />
    </svg>
  );
};
