import type { AllHTMLAttributes, FC } from 'react';

export const Pets: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 324 182" {...props}>
    <path
      fill="currentColor"
      d="M179.59 145.81H139v-21.65H73.31v21.65H33.47v-21.65H1v8.12h10.82L21.89 181h171.23l5.41-48.72h10.82v-8.12h-29.76v21.65z"
    />
    <path
      d="M185 29.42h-54.12V13.18h-48.7v16.24H25.35L14.53 102.5H1v8.12h43.29v21.66h19v-21.66h86.58v21.66h18.94v-21.66h40.59v-8.12h-13.58zM49.71 87.62A5.44 5.44 0 0144.29 93h-8.11a5.44 5.44 0 01-5.42-5.41l2.71-35.19a5.43 5.43 0 015.41-5.4H47a5.42 5.42 0 015.41 5.41zm29.76 0A5.43 5.43 0 0174.06 93h-8.12a5.43 5.43 0 01-5.41-5.41l2.71-35.19a5.42 5.42 0 015.41-5.4h8.11a5.43 5.43 0 015.42 5.41zm35.18 0a5.43 5.43 0 01-5.41 5.38h-8.12a5.43 5.43 0 01-5.41-5.41V52.43a5.42 5.42 0 015.41-5.43h8.12a5.43 5.43 0 015.41 5.41zm8.11-58.2H90.29V21.3h32.47zM144.41 93h-8.12a5.43 5.43 0 01-5.41-5.41l-2.7-35.19a5.42 5.42 0 015.41-5.4h8.12a5.43 5.43 0 015.41 5.41l2.7 35.19a5.43 5.43 0 01-5.41 5.4zm29.77 0h-8.12a5.43 5.43 0 01-5.41-5.41l-2.71-35.19a5.43 5.43 0 015.41-5.4h8.12a5.42 5.42 0 015.41 5.41l2.71 35.19a5.43 5.43 0 01-5.41 5.4z"
      fill="currentColor"
    />
    <path
      d="M241.82 48.37h-35.17c-2.71 35.19 2.7 43.31 2.7 43.31h10.83s-2.71 13.53-2.71 40.6V181h18.94a145.5 145.5 0 015.41-65c5.42 46 10.83 65 10.83 65h18.94s-8.12-43.31-8.12-62.26S271.59 70 271.59 70zm56.83-32.48l-5.41-10.83c-2.71-5.41-2.71-5.41-8.12 0C282.41 7.77 249.94 43 249.94 43L277 61.9s10.2-14.06 12.26-17.07c1.27-1.88 2.55-.59 2.55-.59l14.95 15L323 43z"
      fill="currentColor"
    />
  </svg>
);
