import type { AllHTMLAttributes, FC } from 'react';

export const FamilyKids: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <polygon
      fill="currentColor"
      points="44.67 48.8 46.39 48.8 46.39 42.8 43.97 42.8 44.67 48.8"
    />
    <polygon
      fill="currentColor"
      points="47.72 48.8 49.45 48.8 50.16 42.8 47.72 42.8 47.72 48.8"
    />
    <path
      fill="currentColor"
      d="M47.06,20.27c1.29,0,2.33-1.14,2.33-2.54s-1.04-2.53-2.33-2.53-2.33,1.13-2.33,2.53,1.04,2.54,2.33,2.54"
    />
    <path
      fill="currentColor"
      d="M54.33,34.02l1.67-.94-3.63-9.94c-.25-.49-.63-.96-1.15-1.32-.57-.37-1.23-.64-2.26-.78,0,.05-1.9,2.01-1.9,2.01,0,0-1.86-1.91-1.89-2.01-1.04,.13-1.71,.4-2.27,.78-.51,.36-.89,.83-1.15,1.32l-3.63,9.94,1.66,.94,3.33-6.58,1.08,4.02-3.69,9.52h13.09l-3.7-9.52,1.08-4.02,3.34,6.58Z"
    />
    <path
      fill="currentColor"
      d="M17.02,20.42c1.33,0,2.4-1.15,2.4-2.53s-1.07-2.54-2.4-2.54-2.4,1.13-2.4,2.54,1.07,2.53,2.4,2.53"
    />
    <path
      fill="currentColor"
      d="M22.3,23.29c-.25-.49-.61-.95-1.14-1.32-.55-.36-1.2-.62-2.2-.76,0,.03-.49,.73-1.93,.73s-1.93-.71-1.93-.73c-1.02,.13-1.69,.39-2.24,.76-.53,.37-.89,.83-1.15,1.32l-3.72,9.98,1.75,.93,3.34-6.55v5.16h7.86v-5.16l3.35,6.55,1.78-.93-3.76-9.98Z"
    />
    <polygon
      fill="currentColor"
      points="17.36 34.77 16.5 34.77 13.36 34.77 13.12 48.79 15.25 48.79 16.95 37.49 18.83 48.79 20.94 48.79 20.78 34.77 17.36 34.77"
    />
    <polygon
      fill="currentColor"
      points="31.44 41.15 30.88 41.15 28.83 41.15 28.68 48.8 30.07 48.8 31.17 42.62 32.4 48.8 33.77 48.8 33.64 41.15 31.44 41.15"
    />
    <path
      fill="currentColor"
      d="M31.3,32.7c1.02-.22,1.65-1.26,1.44-2.31-.23-1.08-1.24-1.78-2.27-1.57-1.01,.24-1.65,1.26-1.42,2.32,.23,1.09,1.24,1.79,2.25,1.56"
    />
    <path
      fill="currentColor"
      d="M31.45,33.54c-.69-.02-1.59,.24-1.75,.3-2.32,.67-4.4,5.15-4.4,5.15l.65,.66,2.83-2.56-.17,2.4h5.21l-.12-2.97,3.8,.77,.28-1.01s-2.24-1.76-4.57-2.43c-.17-.06-1.06-.33-1.75-.3"
    />
  </svg>
);
