import type { AllHTMLAttributes, FC } from 'react';

export const Feedback: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    aria-hidden="true"
    focusable="false"
    width="70"
    height="56"
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      d="M.928 38.3225c0 1.3687 1.1576 2.5092 2.5469 2.5092h10.3037v12.6599c0 .9124.5788 1.8248 1.3892 2.281.3474.2281.8104.2281 1.1578.2281.5788 0 1.042-.114 1.505-.4562l20.7231-14.8268H67.381c1.3893 0 2.547-1.1406 2.547-2.5092V2.5099c0-1.3686-1.1577-2.5092-2.547-2.5092H3.475C2.0856.0007.9279 1.1413.9279 2.51v35.8126zM6.0218 5.0191h58.6963v30.6802H37.7433c-.5788 0-1.0419.114-1.505.4562l-17.25 12.4318V38.3225c0-1.3686-1.1577-2.5091-2.547-2.5091H6.1376V5.019H6.022z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
