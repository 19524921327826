import type { AllHTMLAttributes, FC } from 'react';

export const Baggage: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 227 182" {...props}>
    <path
      d="M183.2 37.5V181h23.5a19.4 19.4 0 0019.3-19.4V56.9a19.4 19.4 0 00-19.3-19.4zm-24.2 0V9.4a8.5 8.5 0 00-8.4-8.4H76.4A8.5 8.5 0 0068 9.4v28.1H56.7V181h113.6V37.5zm-14.9 0H82.9V22.4a4.2 4.2 0 014.2-4.2h52.8a4.2 4.2 0 014.2 4.2zM43.8 181V37.5H20.3A19.3 19.3 0 001 56.9v104.7A19.3 19.3 0 0020.3 181z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
