import type { AllHTMLAttributes, FC } from 'react';

export const PrayerRoomsFemale: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      fill="currentColor"
      d="M28.48,34.32c-4.62,0-8.36,3.69-8.46,8.29h16.92c-.1-4.6-3.84-8.29-8.46-8.29"
    />
    <path
      fill="currentColor"
      d="M14.18,56h12.62v-4.77h0c.03-.91,1.68-1.64,1.68-1.64,0,0,1.65,.73,1.68,1.64v4.77h12.62v-12.11H14.18v12.11Z"
    />
    <path
      fill="currentColor"
      d="M11.35,26.33h0c0-.67-.55-1.21-1.22-1.21s-1.21,.54-1.22,1.21l-.04,1.82h2.52l-.03-1.82Z"
    />
    <polygon
      fill="currentColor"
      points="8.49 33.44 8.01 56 12.24 56 11.76 33.44 8.49 33.44"
    />
    <path
      fill="currentColor"
      d="M26.27,32.43c.43,.29,.95,.45,1.47,.45,1.46,0,2.64-1.17,2.64-2.63s-1.18-2.64-2.64-2.64c-.38,0-.76,.09-1.11,.25-.17,.08-.91,.51-.75,.67,.09,.09,.14,.03,.22,0,.24-.11,.5-.16,.75-.16,1.04,0,1.88,.84,1.88,1.88s-.84,1.88-1.88,1.88c-.21,0-.43-.05-.63-.11-.09-.04-.29-.16-.37-.07-.13,.14,.33,.41,.42,.48"
    />
    <polygon
      fill="currentColor"
      points="8.17 32.56 12.19 32.56 12.81 29.03 7.54 29.03 8.17 32.56"
    />
    <polygon
      fill="currentColor"
      points="49 38.3 50.56 38.3 51.19 32.89 49 32.89 49 38.3"
    />
    <polygon
      fill="currentColor"
      points="46.25 38.3 47.8 38.3 47.8 32.89 45.61 32.89 46.25 38.3"
    />
    <path
      fill="currentColor"
      d="M48.4,12.57c1.16,0,2.1-1.02,2.1-2.28s-.94-2.28-2.1-2.28-2.1,1.02-2.1,2.28,.94,2.28,2.1,2.28"
    />
    <path
      fill="currentColor"
      d="M54.96,24.97l1.5-.85-3.27-8.95c-.23-.45-.57-.86-1.04-1.19-.5-.35-1.1-.59-2.03-.7-.05,.09-1.72,1.82-1.72,1.82,0,0-1.72-1.76-1.72-1.82-.93,.11-1.52,.35-2.04,.7-.47,.33-.81,.74-1.03,1.19l-3.28,8.95,1.5,.85,3.02-5.92,.97,3.62-3.34,8.58h11.82l-3.33-8.58,.97-3.62,3.01,5.92Z"
    />
  </svg>
);
