import type { AllHTMLAttributes, FC } from 'react';

export const PrayerRooms: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <path
      fill="currentColor"
      d="M36.52,23.5c-6.29,0-11.39,5.03-11.53,11.29h23.06c-.14-6.26-5.24-11.29-11.53-11.29"
    />
    <path
      fill="currentColor"
      d="M17.04,37.13v15.9h17.18v-6.51h0c.04-1.23,2.29-2.22,2.29-2.22,0,0,2.25,.99,2.29,2.22v6.5h17.19v-15.9H17.04Z"
    />
    <path
      fill="currentColor"
      d="M13.18,12.62h0c-.01-.91-.75-1.65-1.66-1.65s-1.65,.74-1.66,1.65h0l-.05,2.47h3.43l-.05-2.47Z"
    />
    <polygon
      fill="currentColor"
      points="9.29 22.3 8.63 53.03 14.41 53.03 13.74 22.3 9.29 22.3"
    />
    <path
      fill="currentColor"
      d="M33.51,20.93c.59,.4,1.29,.61,2,.61,1.99,0,3.59-1.61,3.59-3.59s-1.61-3.59-3.59-3.59c-.52,0-1.04,.11-1.51,.33-.24,.11-1.25,.69-1.02,.92,.11,.11,.2,.04,.3,0,.32-.15,.68-.22,1.03-.22,1.42,0,2.56,1.14,2.56,2.56s-1.14,2.56-2.56,2.56c-.29,0-.59-.06-.87-.16-.11-.04-.4-.21-.5-.09-.17,.2,.45,.58,.57,.67"
    />
    <polygon
      fill="currentColor"
      points="8.85 21.1 14.32 21.1 15.17 16.29 8 16.29 8.85 21.1"
    />
  </svg>
);
