import type { AllHTMLAttributes, FC } from 'react';

export const Globe: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36.3122 8.31259C30.7744 8.31259 25.3609 9.95474 20.7564 13.0314C16.1519 16.108 12.5631 20.481 10.4439 25.5973C8.32463 30.7135 7.77014 36.3433 8.85052 41.7747C9.93089 47.2062 12.5976 52.1952 16.5134 56.111C20.4293 60.0269 25.4183 62.6936 30.8497 63.774C36.2811 64.8543 41.9109 64.2999 47.0272 62.1806C52.1435 60.0614 56.5164 56.4726 59.5931 51.8681C62.6697 47.2636 64.3119 41.8501 64.3119 36.3123C64.3119 28.8863 61.3619 21.7645 56.111 16.5135C50.86 11.2626 43.7382 8.31259 36.3122 8.31259V8.31259ZM36.3122 59.891C33.5122 59.891 30.4175 55.3226 28.7965 48.1016H43.8279C42.2069 55.3226 39.1122 59.891 36.3122 59.891ZM27.9123 43.6806C27.3226 38.7861 27.3226 33.8385 27.9123 28.9439H44.7121C45.0063 31.389 45.1539 33.8495 45.1542 36.3123C45.1539 38.775 45.0063 41.2355 44.7121 43.6806H27.9123ZM36.3122 12.7336C39.1122 12.7336 42.2069 17.302 43.8279 24.5229H28.7965C30.4175 17.302 33.5122 12.7336 36.3122 12.7336ZM49.1331 28.9439H58.712C59.4727 31.3269 59.8702 33.8109 59.8909 36.3123C59.8702 38.8137 59.4727 41.2977 58.712 43.6806H49.1331C49.4273 41.2355 49.5749 38.775 49.5752 36.3123C49.5749 33.8495 49.4273 31.389 49.1331 28.9439ZM56.6488 24.5229H48.3963C47.6772 20.9701 46.4357 17.5435 44.7121 14.3546C49.7533 16.2513 53.9748 19.8474 56.6488 24.5229V24.5229ZM27.9123 14.3546C26.1887 17.5435 24.9472 20.9701 24.2281 24.5229H15.9756C18.6496 19.8474 22.8711 16.2513 27.9123 14.3546V14.3546ZM13.9125 28.9439H23.4913C22.9016 33.8385 22.9016 38.7861 23.4913 43.6806H13.9125C13.1517 41.2977 12.7542 38.8137 12.7335 36.3123C12.7542 33.8109 13.1517 31.3269 13.9125 28.9439V28.9439ZM15.9756 48.1016H24.2281C24.9472 51.6544 26.1887 55.0811 27.9123 58.2699C22.8711 56.3733 18.6496 52.7772 15.9756 48.1016V48.1016ZM44.7121 58.2699C46.4357 55.0811 47.6772 51.6544 48.3963 48.1016H56.6488C53.9748 52.7772 49.7533 56.3733 44.7121 58.2699V58.2699Z"
      fill="currentColor"
    />
  </svg>
);
