import type { AllHTMLAttributes, FC } from 'react';

export const Customs: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 184 182" {...props}>
    <path fill="currentColor" d="M27.9 154.1h55.2v26.87H27.9z" />
    <path
      d="M169 159h6c6 0 8-1.9 8-7.9V91.3c0-6-2.3-8.3-8.3-8.3H169v76H93v13.7c0 6 2.3 8.3 8.3 8.3h59.9c6 0 7.8-2 7.8-8zM22.5 63.4C8.6 63.4 1 75.7 1 90.1V181h21.5V92.7h5.4v50.2h54.4z"
      fillRule="evenodd"
      fill="currentColor"
    />
    <path
      d="M82.9 126.9V90h2.7l22 65.5 20-8.7-25.5-74.2C99 63.5 90.3 60.7 82 60.7H33.4zM78 19H32.8v10.2c0 12.1 9.8 23.5 21.9 23.5s21.9-11.4 21.9-23.5h17zm0-5.4L88 1 30.6 7.3l2.2 6.3H78z"
      fillRule="evenodd"
      fill="currentColor"
    />
  </svg>
);
