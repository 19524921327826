import type { AllHTMLAttributes, FC } from 'react';

export const BabyChild: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 179 182" {...props}>
    <path
      d="M124.9 72.6c1-1.4 1.2-3 .4-4s-3.8-1.8-4.2-3.8 0-2.8 1.2-5.3 2-3.4 2.1-4.2a1.7 1.7 0 00-.6-1.6l-2.9-2.9-17.8 17.8 2.8 2.9a2.1 2.1 0 001.7.6c.8-.1 2.2-1.2 4.2-2.1s3.3-1.6 5.3-1.2 2.3 3 3.8 4.2 2.4.9 4-.4zm47.9 11.1v5.1H6.2v-5.1H1V181h5.2v-20.4h166.6V181h5.2V83.7zM26.5 146.1H7.2v-51h19.3zm24.6 0H31.8v-51h19.3zm24.5 0H56.3v-51h19.3zm24.5 0H80.9v-51h19.2zm24.6 0h-19.3v-51h19.3zm24.5 0h-19.3v-51h19.3zm24.6 0h-19.3v-51h19.3zM71 1L53.2 18.8l47.4 47.3 17.8-17.8zM56.9 18.8L71 4.7l43.7 43.6-14.1 14.1z"
      fillRule="evenodd"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M85.3 31.9l1.9 1.9 4.6-4.6-1.8-1.9-4.7 4.6z"
    />
    <path
      fill="currentColor"
      d="M96.787 43.333l4.659-4.676 1.856 1.85-4.659 4.675zm-5.801-5.713l4.65-4.682 1.86 1.846-4.651 4.683z"
    />
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M102.5 49.1l1.8 1.8 4.7-4.6-1.9-1.9-4.6 4.7z"
    />
  </svg>
);
