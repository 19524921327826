import type { AllHTMLAttributes, FC } from 'react';

export const Restaurant: FC = (props: AllHTMLAttributes<SVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <polygon
        fill="currentColor"
        points="39.16 55.98 41.69 55.98 42.06 34.49 38.64 33.34 39.16 55.98"
      />
      <path
        fill="currentColor"
        d="M44.77,15.39c-.13-7.1-7.29-7.34-7.29-7.34l.96,22.48,3.28,1.13s3.23-7.44,3.05-16.28"
      />
      <path
        fill="currentColor"
        d="M29.54,8.81v-.08c0-.42-.33-.73-.73-.73s-.73,.31-.73,.73l-.02,10.81h-1.47l.02-10.73v-.08c0-.42-.33-.73-.72-.73s-.74,.31-.74,.72v10.82h-1.47V8.81s.02-.06,.02-.08c0-.42-.35-.73-.74-.73s-.74,.31-.74,.72v10.82h-1.46V8.8s.01-.05,.01-.08c0-.41-.34-.72-.73-.72-.42,0-.75,.31-.75,.72v10.82c0,.59-.02,1.16-.05,1.75,0,2.14,2.97,3.46,3.03,5l.72,29.7h2.8l.75-29.7c.06-1.55,3.02-2.85,3.02-5.01,0-.19,0-.8-.03-1.63l.02-10.85Z"
      />
    </svg>
  );
};
