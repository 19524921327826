import type { AllHTMLAttributes, FC } from 'react';

export const Drink: FC = (props: AllHTMLAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 64 64"
      width="64"
      height="64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48.0537 31.717H9V40.9352C9.02865 45.0339 10.5036 48.9872 13.1587 52.082C15.8139 55.1769 19.4743 57.2093 23.4797 57.8127C27.4852 58.4161 31.5717 57.5507 35.0022 55.3727C38.4327 53.1947 40.9812 49.8474 42.1879 45.9349H48.0537C49.896 45.9349 51.6628 45.1941 52.9655 43.8756C54.2682 42.5571 55 40.7688 55 38.9041C55.0004 37.0247 54.2736 35.2199 52.9751 33.8765C51.6767 32.5331 49.91 31.7579 48.0537 31.717ZM48.0537 42.8101H42.9597V34.8418H48.0537C49.0911 34.8822 50.0727 35.328 50.7923 36.0855C51.5119 36.843 51.9135 37.8532 51.9128 38.9041C51.9128 39.94 51.5062 40.9335 50.7825 41.666C50.0587 42.3985 49.0772 42.8101 48.0537 42.8101Z"
        fill="currentColor"
      />
      <path
        d="M15.7919 25.3112H18.8792C18.893 24.8668 18.996 24.43 19.1817 24.0271C19.3674 23.6242 19.6321 23.2638 19.9597 22.9676C20.5857 22.4276 21.089 21.7572 21.4354 21.0022C21.7817 20.2471 21.9629 19.4253 21.9664 18.5929C21.9649 17.7374 21.7855 16.8918 21.4397 16.1111C21.094 15.3305 20.5897 14.6323 19.9597 14.0619C19.6321 13.7658 19.3674 13.4053 19.1817 13.0024C18.996 12.5996 18.893 12.1627 18.8792 11.7183C18.893 11.274 18.996 10.8371 19.1817 10.4342C19.3674 10.0313 19.6321 9.67088 19.9597 9.37472C20.6115 8.85751 21.1331 8.19151 21.4819 7.43117C21.8307 6.67083 21.9967 5.83773 21.9664 5H18.8792C18.8654 5.44436 18.7624 5.88123 18.5767 6.2841C18.3909 6.68697 18.1262 7.04743 17.7987 7.3436C17.1727 7.88356 16.6693 8.55397 16.323 9.30901C15.9767 10.064 15.7955 10.8859 15.7919 11.7183C15.7934 12.5738 15.9729 13.4194 16.3186 14.2001C16.6644 14.9807 17.1687 15.6789 17.7987 16.2493C18.4073 16.7914 18.7809 17.5541 18.8387 18.3726C18.8964 19.1911 18.6338 19.9998 18.1074 20.624L17.7987 20.9365C17.1469 21.4537 16.6253 22.1197 16.2765 22.88C15.9277 23.6404 15.7617 24.4735 15.7919 25.3112Z"
        fill="currentColor"
      />
      <path
        d="M23.5101 25.3112H26.5973C26.6112 24.8668 26.7141 24.43 26.8998 24.0271C27.0856 23.6242 27.3503 23.2638 27.6778 22.9676C28.3038 22.4276 28.8072 21.7572 29.1535 21.0022C29.4998 20.2471 29.681 19.4253 29.6846 18.5929C29.6831 17.7374 29.5036 16.8918 29.1579 16.1111C28.8121 15.3305 28.3078 14.6323 27.6778 14.0619C27.3503 13.7658 27.0856 13.4053 26.8998 13.0024C26.7141 12.5996 26.6112 12.1627 26.5973 11.7183C26.6112 11.274 26.7141 10.8371 26.8998 10.4342C27.0856 10.0313 27.3503 9.67088 27.6778 9.37472C28.3296 8.85751 28.8512 8.19151 29.2 7.43117C29.5488 6.67083 29.7148 5.83773 29.6846 5H26.5973C26.5835 5.44436 26.4806 5.88123 26.2948 6.2841C26.1091 6.68697 25.8444 7.04743 25.5168 7.3436C24.8908 7.88356 24.3875 8.55397 24.0411 9.30901C23.6948 10.064 23.5136 10.8859 23.5101 11.7183C23.5116 12.5738 23.691 13.4194 24.0368 14.2001C24.3825 14.9807 24.8868 15.6789 25.5168 16.2493C26.1255 16.7914 26.499 17.5541 26.5568 18.3726C26.6146 19.1911 26.3519 19.9998 25.8255 20.624L25.5168 20.9365C24.865 21.4537 24.3434 22.1197 23.9946 22.88C23.6458 23.6404 23.4798 24.4735 23.5101 25.3112Z"
        fill="currentColor"
      />
      <path
        d="M31.2282 25.3112H34.3154C34.3293 24.8668 34.4322 24.43 34.6179 24.0271C34.8037 23.6242 35.0684 23.2638 35.396 22.9676C36.0219 22.4276 36.5253 21.7572 36.8716 21.0022C37.218 20.2471 37.3991 19.4253 37.4027 18.5929C37.4012 17.7374 37.2217 16.8918 36.876 16.1111C36.5302 15.3305 36.026 14.6323 35.396 14.0619C35.0684 13.7658 34.8037 13.4053 34.6179 13.0024C34.4322 12.5996 34.3293 12.1627 34.3154 11.7183C34.3293 11.274 34.4322 10.8371 34.6179 10.4342C34.8037 10.0313 35.0684 9.67088 35.396 9.37472C36.0478 8.85751 36.5694 8.19151 36.9181 7.43117C37.2669 6.67083 37.433 5.83773 37.4027 5H34.3154C34.3016 5.44436 34.1987 5.88123 34.0129 6.2841C33.8272 6.68697 33.5625 7.04743 33.2349 7.3436C32.6089 7.88356 32.1056 8.55397 31.7592 9.30901C31.4129 10.064 31.2318 10.8859 31.2282 11.7183C31.2297 12.5738 31.4091 13.4194 31.7549 14.2001C32.1006 14.9807 32.6049 15.6789 33.2349 16.2493C33.8436 16.7914 34.2172 17.5541 34.2749 18.3726C34.3327 19.1911 34.07 19.9998 33.5436 20.624L33.2349 20.9365C32.5831 21.4537 32.0615 22.1197 31.7127 22.88C31.364 23.6404 31.1979 24.4735 31.2282 25.3112Z"
        fill="currentColor"
      />
    </svg>
  );
};
