import type { AllHTMLAttributes } from 'react';

export const PrmPhone = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 120 121" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m93.565 89.457-18.427-16.923-2.6325 2.6325 18.427 16.923 2.6325-2.6324z"
      fill="currentColor"
      clipRule="evenodd"
      fillRule="evenodd"
    />
    <path
      d="m55.959 40.944-6.0171-24.444-3.7607 1.1282 6.3932 24.068 3.3846-0.7521z"
      fill="currentColor"
      clipRule="evenodd"
      fillRule="evenodd"
    />
    <path
      d="m38.282 71.406c4.5128 8.2735 15.043 19.932 22.188 24.82 3.7607 2.6325 7.8974 5.6415 12.41 8.2735 0 0 3.7606-1.88 6.017-3.385 2.6325-1.5039 3.3847-1.88 6.3932-4.5124 1.5043-1.5043 4.1368-3.3847 4.1368-3.3847l-18.427-16.923c-1.5043 1.8803-3.7607 4.5128-5.2649 7.1453-2.2565-1.8804-3.7607-3.3847-8.2736-8.6496-1.8803-1.8804-5.641-7.5214-6.7692-9.7778-1.5043-2.2564-4.1367-7.1453-5.2649-10.154-2.6325-6.3932-3.0086-8.6496-3.7607-11.282 3.0085-0.3761 6.3931-0.7521 9.0256-1.5043l-6.3932-24.068s-3.0085 1.1283-4.8888 2.2565c-3.7607 1.5042-4.1368 1.8803-6.7693 3.7606-2.2564 1.1282-5.641 3.7607-5.641 3.7607 0 5.265 0.7521 10.154 1.5043 14.667 0.7521 8.6496 4.5128 21.06 9.7778 28.957z"
      fill="currentColor"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
);
