import type { AllHTMLAttributes, FC } from 'react';

export const CallEnd: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 124 46" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7649 8.1583C5.21116 9.88827 0.700532 15.8846 0.853914 22.6632L0.609618 37.0712C0.554974 38.7105 1.20839 40.2948 2.40316 41.4197C3.59792 42.5447 5.21847 43.1016 6.85133 42.9483C7.42765 42.9586 7.72069 42.6757 8.00396 42.969L32.2971 38.2167C35.1794 37.6863 37.2776 35.1844 37.2984 32.2532L37.4645 22.4557C37.5489 20.3028 39.1528 18.5159 41.2838 18.2005C48.1719 17.365 55.1182 17.1041 62.0507 17.4204C68.9897 17.3531 75.9229 17.8632 82.7784 18.9455C84.8975 19.3371 86.4399 21.1805 86.4512 23.3352L86.2851 33.1327C86.2065 36.0628 88.2186 38.6385 91.0811 39.272L115.198 44.8935C118.502 45.5862 121.785 43.6447 122.769 40.4174C122.778 39.8411 123.071 39.5581 122.788 39.2648L123.032 24.8567C123.416 18.0878 119.111 11.9332 112.62 9.96902C96.4685 4.17521 79.4847 1.04699 62.3341 0.706969C45.1824 0.431139 28.1029 2.94779 11.7649 8.1583Z"
      fill="currentColor"
    />
  </svg>
);
