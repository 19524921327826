import type { AllHTMLAttributes, FC } from 'react';

export const MoveToMobile: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 45"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26 37H1v-3h25v3Z"
      clipRule="evenodd"
    />
    <circle cx="13.5" cy="39.5" r="1.5" fill="currentColor" />
    <rect
      width="24"
      height="42"
      x="1.5"
      y="1.5"
      stroke="currentColor"
      strokeWidth="3"
      rx="2.5"
    />
    <path
      fill="currentColor"
      d="M8 24v-2.538c0-1.41.458-2.61 1.375-3.597.917-.987 2.03-1.48 3.34-1.48h1.57V13L19 18.077l-4.714 5.077v-3.385h-1.572c-.445 0-.818.163-1.12.488-.302.324-.452.726-.451 1.204V24H8Z"
    />
  </svg>
);
