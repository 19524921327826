import type { AllHTMLAttributes, FC } from 'react';

export const Agent: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    width="91"
    height="91"
    viewBox="0 0 91 91"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M90.9054 49.8018C90.2494 43.803 85.25 39.1221 79.3683 38.4631C76.7216 38.1905 74.0522 38.3268 71.3603 38.3041C68.6683 38.2814 67.3788 39.5766 67.3788 42.3033V76.9783C67.3788 79.2278 65.9763 80.6594 63.7367 80.6594C60.6602 80.6594 57.5836 80.6366 54.5297 80.6594C54.3263 80.6811 54.1207 80.66 53.926 80.5974C53.7312 80.5348 53.5516 80.4321 53.3986 80.2958C52.5784 79.0435 51.375 78.0932 49.9699 77.5883C48.5647 77.0834 47.0343 77.0512 45.6094 77.4968C44.1845 77.9423 42.9426 78.8413 42.071 80.0581C41.1995 81.2748 40.7456 82.7434 40.778 84.242C40.8103 85.7406 41.3271 87.188 42.2503 88.3657C43.1735 89.5434 44.453 90.3875 45.8958 90.7706C47.3385 91.1537 48.8662 91.0551 50.2482 90.4895C51.6302 89.9239 52.7915 88.9221 53.5569 87.6353C53.7882 87.5192 54.0452 87.4644 54.3035 87.4762C57.199 87.4535 60.1172 87.3399 63.0128 87.4989C69.0981 87.7943 74.0296 83.3634 74.188 77.0919H77.536C82.1508 77.001 85.8382 75.0696 88.5528 71.3203C89.8196 69.5934 90.7923 67.6619 90.9054 65.4805C91.0638 64.1626 90.9959 50.9379 90.9054 49.8018ZM84.2773 63.6627C84.248 65.4112 83.542 67.0794 82.3088 68.3138C81.0757 69.5482 79.4125 70.2517 77.6717 70.275H74.1653V45.2345C78.0789 45.2573 80.8388 44.9846 83.0783 48.0067C83.8693 49.0956 84.2969 50.408 84.2999 51.756C84.2999 55.7097 84.3225 59.6862 84.2773 63.6627Z"
      fill="currentColor"
    />
    <path
      d="M81.2694 27.5357C80.9979 26.877 80.7264 26.241 80.4322 25.605C80.0974 24.7057 79.6965 23.8325 79.233 22.9928C73.0108 11.3628 60.8605 3.48083 46.9002 3.48083C34.5236 3.48083 23.5725 9.68195 16.9204 19.1994C16.3053 20.0663 15.7677 20.986 15.314 21.9479C14.0815 24.1369 13.0296 26.4236 12.1689 28.785C10.9245 32.1922 10.4267 35.7584 10.2683 39.3701C10.2626 39.5518 10.2194 39.7304 10.1415 39.8946C10.0636 40.0588 9.95274 40.205 9.81581 40.3241C6.354 42.6637 4.22713 45.8665 3.61623 50.0233C3.48047 51.0227 3.48047 56.9512 3.48047 56.9512C3.48047 56.9512 3.48047 62.221 3.63885 65.4465C4.5439 71.4659 9.25015 76.2133 15.314 76.8947C17.9839 77.19 20.7216 77.031 23.4368 77.0537C26.1519 77.0765 27.2606 75.6909 27.2606 73.1922V42.2321C27.238 39.5518 25.9483 38.257 23.3237 38.257H17.1241C17.2825 37.1213 17.373 36.1219 17.554 35.1224C21.242 14.5656 44.4792 4.00327 62.3312 14.8155C68.2593 18.4044 72.4452 23.4925 74.9567 29.9889C75.6807 31.8969 77.5587 32.7601 79.3688 32.0786C81.1789 31.3972 82.0387 29.4437 81.2694 27.5357ZM20.4501 45.0715V70.2166H17.9386C16.5132 70.1939 15.2008 70.1712 13.8885 69.4443C11.5807 68.1723 10.3136 66.1734 10.2683 63.5158C10.2231 59.6089 10.2457 55.7019 10.2683 51.7723C10.2862 49.9928 11.0013 48.2919 12.259 47.0379C13.5167 45.7838 15.2157 45.0773 16.9883 45.0715H20.4501Z"
      fill="currentColor"
    />
  </svg>
);
