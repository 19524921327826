import type { AllHTMLAttributes, FC } from 'react';

export const Services: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 182" {...props}>
    <path
      d="M106 1l-5.2 1.9L113 21.3l-15.1 5.5-7.2-6.1-4.7 1.7 3.3 8.9c.5 1.5 1.3 4.2 2.5 4.8 7.4 2.5 24.6-5.5 24.6-5.5l2.3 22.4 5.2-1.9 4.2-24.6 10.8-3.9c3.7-1.3 9.9-6 9-8.5s-8.8-2.5-12.6-1.1l-10.7 3.9z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path fill="none" d="M-968.1-1533.5H475.5v2566.42H-968.1z" />
    <path
      d="M10.7 93.5h16V146l.4 34.9h22.2V93.5h16L75 45.7H57l5.1-25.4H49.3a12.9 12.9 0 00-4.5-17.6 13.1 13.1 0 00-17.7 4.5 13.3 13.3 0 000 13.1H13.9L19 45.7H1z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
