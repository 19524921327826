import type { AllHTMLAttributes, FC } from 'react';

export const Whatsapp: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg aria-hidden="true" viewBox="56 56 110 110" {...props}>
    <g fill="currentColor">
      <path d="m163 109.7c0 27.9-22.9 50.6-51.1 50.6a51.1 51.1 0 0 1 -24.6-6.3l-28.3 9 9.2-27.2a50.9 50.9 0 1 1 94.8-26.1m-51.1-42.6a42.8 42.8 0 0 0 -42.9 42.6 42.2 42.2 0 0 0 8.2 24.9l-5.4 15.9 16.5-5.3a42.6 42.6 0 0 0 23.6 7.1c23.7 0 43-19.2 43-42.6s-19.3-42.6-43-42.6" />
      <path d="m137.7 121.3c-.3-.5-1.1-.8-2.4-1.4l-8.5-4.1c-1.2-.4-2-.6-2.8.7s-3.3 4-4 4.8-1.5 1-2.7.3a33.3 33.3 0 0 1 -10.1-6.1 37.5 37.5 0 0 1 -6.9-8.6c-.8-1.3-.1-1.9.5-2.6a19.9 19.9 0 0 0 1.9-2.1 11.3 11.3 0 0 0 1.2-2.1 2.1 2.1 0 0 0 -.1-2.2c-.3-.6-2.8-6.7-3.8-9.2s-2.1-2.1-2.8-2.1h-2.4a4.6 4.6 0 0 0 -3.4 1.6 13.8 13.8 0 0 0 -4.4 10.2c0 6.2 4.5 12.1 5.2 12.9s8.6 13.8 21.3 18.7 12.8 3.3 15 3.1 7.5-3 8.5-5.9 1-5.4.7-5.9" />
    </g>
  </svg>
);
