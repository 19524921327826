import type { AllHTMLAttributes, FC } from 'react';

export const MoonLantern: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <path
      fill="currentColor"
      d="M16.16,43.96c.54-.62,2.01,.24,2.61,.45,1.42,.53,2.96,.83,4.48,.83,7.33,0,13.24-5.91,13.24-13.24s-5.91-13.24-13.24-13.24c-.29,0-.59,.03-.88,.05v5.2c1.72,.09,3.13,1.26,3.48,2.82h1.45v.77h-.37v.38h-.27v9.16h.27v.33h.37v.77h-1.85c-.86,1.22-3.27,2.03-3.27,2.03,0,0-2.45-.81-3.33-2.03h-1.86v-.77h.37v-.33h.27v-9.16h-.27v-.38h-.37v-.77h1.45c.36-1.56,1.77-2.73,3.48-2.82v-5.18c-1.39,.14-2.75,.49-4.02,1.06-.53,.24-.98,.6-1.57,.03-1.19-1.18,4.06-4.21,5.28-4.77,2.43-1.13,5.12-1.7,7.82-1.7,10.25,0,18.55,8.3,18.55,18.55s-8.29,18.56-18.55,18.56c-3.7,0-7.3-1.12-10.35-3.17h0c-.65-.43-3.83-2.4-2.94-3.43Zm8.23-7.7h1.53s.11-4.52,0-6.03c-.02-.25-.11-.75-.18-.97-.1-.34-.43-1.07-.59-1.08-.16,0-.5,.85-.58,1.08-.11,.28-.17,.73-.18,.97-.08,1.5,0,6.03,0,6.03Zm-4.45-6.03c-.02-.25-.11-.75-.18-.97-.1-.34-.43-1.07-.59-1.08-.16,0-.5,.85-.58,1.08-.11,.28-.17,.73-.18,.97-.08,1.5,0,6.03,0,6.03h1.53s.11-4.52,0-6.03Zm1.2-.97c-.19,.28-.29,.72-.31,.97-.13,1.5,0,6.03,0,6.03h2.68s.2-4.53,0-6.03c-.03-.25-.2-.75-.31-.97-.17-.34-.76-1.07-1.03-1.08-.27,0-.87,.85-1.02,1.08Z"
    />
  </svg>
);
