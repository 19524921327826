import type { FC, HTMLAttributes } from 'react';

export const Valid: FC = (props: HTMLAttributes<SVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" {...props}>
      <path
        d="M22.0013 43.3333C33.7834 43.3333 43.3346 33.782 43.3346 22C43.3346 10.2179 33.7834 0.666626 22.0013 0.666626C10.2192 0.666626 0.667969 10.2179 0.667969 22C0.667969 33.782 10.2192 43.3333 22.0013 43.3333Z"
        style={{ fill: 'var(--color-success, #128a0b)' }}
      />
      <path
        d="M18.4474 31.5999L9.91406 23.4221L12.7585 20.5777L18.4474 26.6221L31.603 13.8221L34.0918 16.311L18.4474 31.5999Z"
        style={{ fill: 'var(--color-primary, white)' }}
      />
    </svg>
  );
};
