import type { AllHTMLAttributes, FC } from 'react';

export const Phone: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg viewBox="0 0 96 88" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.6587 65.1018C95.0633 64.1354 93.4911 63.5516 91.1312 62.3906C88.767 61.2295 77.1519 55.6059 74.9924 54.834C72.8268 54.0599 71.2477 53.6711 69.6756 55.9951C68.103 58.3214 63.5755 63.5516 62.195 65.1018C60.8165 66.6562 59.4402 66.8508 57.0764 65.6875C54.7168 64.5264 47.1075 62.0702 38.085 54.1572C31.0649 48 26.3235 40.3982 24.9449 38.0696C23.5691 35.7452 24.8012 34.4892 25.9812 33.3323C27.0454 32.2902 28.3454 30.6191 29.525 29.2635C30.7096 27.9056 31.1021 26.9391 31.8849 25.387C32.6766 23.8364 32.2817 22.4811 31.6887 21.3155C31.0998 20.1544 26.3714 8.72139 24.4044 4.06882C22.437 -0.579576 20.4719 0.194206 19.0914 0.194206C17.7155 0.194206 16.1388 0 14.5639 0C12.9894 0 10.429 0.58148 8.26336 2.90549C6.10005 5.23215 0 10.8535 0 22.2842C0 33.7173 8.46002 44.7631 9.64384 46.3114C10.8238 47.8593 25.9789 72.083 49.9907 81.3866C74.0049 90.6853 74.0049 87.5826 78.3357 87.1938C82.6631 86.8065 92.3069 81.5747 94.2824 76.1518C96.2452 70.7209 96.2452 66.0702 95.6587 65.1018Z"
      fill="currentColor"
    />
  </svg>
);
