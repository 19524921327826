import type { AllHTMLAttributes, FC } from 'react';

export const Football: FC = (props: AllHTMLAttributes<SVGElement>) => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_237_7776)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64 0C99.2937 0 128 28.7063 128 64C128 99.4606 98.9554 128 64 128C28.8754 128 0 99.4606 0 64C0 28.7063 28.7063 0 64 0ZM80.549 118.713L77.0025 116.686L81.8999 89.6669L109.255 85.9517L112.971 93.3821C117.192 86.4589 119.894 78.3526 120.738 69.9101L114.153 74.3003L92.5376 57.0763L102.163 31.2386L111.112 31.7448C104.527 22.1199 95.0704 14.5208 83.9239 10.468L86.2882 17.3912L63.9979 33.602L41.7077 17.3912L43.9032 10.468C32.9272 14.5206 23.4701 22.1199 16.8838 31.7448L25.665 31.2386L35.2899 57.0763L13.8445 74.3003L7.08994 69.9101C7.93458 78.3531 10.8051 86.4592 15.0266 93.3821L18.5731 85.9517L45.9286 89.6669L50.8259 116.686L47.2794 118.713C58.0865 121.921 69.7384 121.921 80.5457 118.713H80.549ZM64 42.7246L41.7097 58.9353L50.1527 85.109H77.6773L86.2898 58.9353L64 42.7246Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_237_7776">
        <rect width="128" height="128" fill="transparent" />
      </clipPath>
    </defs>
  </svg>
);
